import { useEffect, useState } from 'react';
import { Grid, createStyles, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useDashboardFilters from 'src/hooks/useDashboardFilters';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import Button from 'src/components/Button';
import { useDashboardStyles } from '../styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      gap: 8,
      flexWrap: 'wrap',
    },
    fitlerItem: {
      width: 'auto',
      border: '1px solid #C3C3C7',
      gap: 4,
      borderRadius: 8,
      padding: '4px 8px',
    },
  })
);

const FilterItem = ({
  content,
  isViewer,
  removeFilter,
}: {
  content: string;
  isViewer: boolean;
  removeFilter: () => void;
}) => {
  const classes = useStyles();
  const fontClasses = useDashboardStyles();

  if (!content) {
    return <></>;
  }

  return (
    <Grid item container direction='row' className={classes.fitlerItem}>
      <Typography className={fontClasses.textXSRegular}>{content}</Typography>
      {!isViewer && (
        <CloseIcon
          style={{ cursor: 'pointer', fontSize: 16 }}
          onClick={removeFilter}
        />
      )}
    </Grid>
  );
};

export const FilterSection = ({ isViewer }: { isViewer: boolean }) => {
  const classes = useStyles();
  const fontClasses = useDashboardStyles();

  const [filteredBrandSuitabilityFilters, setFilteredBrandSuitabilityFilters] =
    useState<boolean>(false);

  const {
    garmSourceFilters,
    setGarmSourceFilters,
    riskChangeFilter,
    selectedPublishers,
    selectedIABCategories,
    selectIABCategories,
    selectPuplisher,
    setRiskChangeFilter,
    setFilterValue,
    getFilterValue,
    selectedGenres,
    selectGenres,
    selectedCustomFilter,
    setSelectedCustomFilter,
    selectedCustomTags,
    setSelectedCustomTags,
    selectedLanguages,
    selectLanguages,
    setEnabledUpdateDashboard,
    electionRange,
    setElectionRange,
    riskFilters,
  } = useDashboardFilters();
  const { dashboardCategories } = useDashboardCategories();

  const podcastRating = getFilterValue('audience') as number[];

  const iabFilters =
    selectedIABCategories.filter(
      (item) => !(item.children && item.children.length)
    ) || [];

  const showClearAll =
    garmSourceFilters.length ||
    (podcastRating && (podcastRating[0] !== 0 || podcastRating[1] !== 5)) ||
    (iabFilters && iabFilters.length) ||
    selectedCustomFilter ||
    filteredBrandSuitabilityFilters ||
    (selectedGenres && selectedGenres.length) ||
    (selectedCustomTags && selectedCustomTags.length) ||
    (selectedPublishers && selectedPublishers.length) ||
    (selectedLanguages && selectedLanguages.length) ||
    riskChangeFilter !== 'all';

  const getFilterTempValue = (key: string) => riskFilters[key]?.value;

  useEffect(() => {
    dashboardCategories.forEach((cat) => {
      if (cat.enabled) {
        cat.entries.forEach((entry: any) => {
          const value: number[] = getFilterTempValue(
            entry.jsonKeyRisk
          ) as number[];
          if (value && (value[0] !== 0 || value[1] !== 3)) {
            setFilteredBrandSuitabilityFilters(true);
          }
        });
        if (
          electionRange.length > 1 &&
          (electionRange[0] !== 0 || electionRange[1] !== 100)
        ) {
          setFilteredBrandSuitabilityFilters(true);
        }
      }
    });
  }, [dashboardCategories, riskFilters, electionRange]);

  const clearBrandSuitabilityFilters = () => {
    setFilteredBrandSuitabilityFilters(false);
    dashboardCategories.forEach((cat) => {
      if (cat.enabled) {
        cat.entries.forEach((entry: any) => {
          handleFilterChange(entry.jsonKeyRisk, [0, 3]);
        });
        setElectionRange([0, 100]);
      }
    });
  };

  const handleFilterChange = (key: string, n: number | number[]) => {
    setFilterValue(key, n);
  };

  const handleClearAllFilters = async () => {
    await new Promise<void>((resolve) => {
      handleFilterChange('audience', [0, 5]);
      selectIABCategories([]);
      setSelectedCustomFilter(null);
      selectGenres([]);
      setSelectedCustomTags([]);
      selectPuplisher([]);
      selectLanguages([]);
      setRiskChangeFilter('all');
      setGarmSourceFilters([]);
      clearBrandSuitabilityFilters();
      resolve();
    });

    setEnabledUpdateDashboard(true);
  };

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      className={classes.container}
    >
      <Typography className={fontClasses.textSMBold} style={{ marginRight: 8 }}>
        Applied Filters:
      </Typography>
      {!!garmSourceFilters.length && (
        <FilterItem
          isViewer={isViewer}
          content={'Selected Podcasts'}
          removeFilter={async () => {
            await new Promise<void>((resolve) => {
              setGarmSourceFilters([]);
              resolve();
            });

            setEnabledUpdateDashboard(true);
          }}
        />
      )}
      {!!podcastRating && (
        <FilterItem
          isViewer={isViewer}
          content={
            podcastRating[0] === 0 && podcastRating[1] === 5
              ? ''
              : `Podcast Rating:  >${podcastRating[0]}, <${podcastRating[1]}`
          }
          removeFilter={async () => {
            await new Promise<void>((resolve) => {
              handleFilterChange('audience', [0, 5]);
              resolve();
            });

            setEnabledUpdateDashboard(true);
          }}
        />
      )}
      <FilterItem
        isViewer={isViewer}
        content={
          iabFilters && iabFilters.length
            ? `IAB: ${iabFilters[0].name}${
                iabFilters.length > 2
                  ? ', ' +
                    iabFilters[1].name +
                    ', +' +
                    (iabFilters.length - 2) +
                    ' more...'
                  : iabFilters.length === 2
                  ? ', ' + iabFilters[1].name
                  : ''
              }`
            : ''
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            selectIABCategories([]);
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      <FilterItem
        isViewer={isViewer}
        content={
          selectedCustomFilter
            ? `Brand Standard Filter: ${selectedCustomFilter.name}`
            : ''
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            setSelectedCustomFilter(null);
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      <FilterItem
        isViewer={isViewer}
        content={
          filteredBrandSuitabilityFilters
            ? 'Selected Brand Suitability Filters'
            : ''
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            clearBrandSuitabilityFilters();
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      <FilterItem
        isViewer={isViewer}
        content={
          selectedGenres && selectedGenres.length
            ? `Genres: ${selectedGenres[0]}${
                selectedGenres.length > 2
                  ? ', ' +
                    selectedGenres[1] +
                    ', +' +
                    (selectedGenres.length - 2) +
                    ' more...'
                  : selectedGenres.length === 2
                  ? ', ' + selectedGenres[1]
                  : ''
              }`
            : ''
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            selectGenres([]);
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      <FilterItem
        isViewer={isViewer}
        content={
          selectedCustomTags && selectedCustomTags.length
            ? `Custom Tags: ${selectedCustomTags[0]}${
                selectedCustomTags.length > 2
                  ? ', ' +
                    selectedCustomTags[1] +
                    ', +' +
                    (selectedCustomTags.length - 2) +
                    ' more...'
                  : selectedCustomTags.length === 2
                  ? ', ' + selectedCustomTags[1]
                  : ''
              }`
            : ''
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            setSelectedCustomTags([]);
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      <FilterItem
        isViewer={isViewer}
        content={
          selectedPublishers && selectedPublishers.length
            ? `Publisher: ${selectedPublishers[0]}${
                selectedPublishers.length > 2
                  ? ', ' +
                    selectedPublishers[1] +
                    ', +' +
                    (selectedPublishers.length - 2) +
                    ' more...'
                  : selectedPublishers.length === 2
                  ? ', ' + selectedPublishers[1]
                  : ''
              }`
            : ''
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            selectPuplisher([]);
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      <FilterItem
        isViewer={isViewer}
        content={
          selectedLanguages && selectedLanguages.length
            ? `Languages: ${selectedLanguages[0]}${
                selectedLanguages.length > 2
                  ? ', ' +
                    selectedLanguages[1] +
                    ', +' +
                    (selectedLanguages.length - 2) +
                    ' more...'
                  : selectedLanguages.length === 2
                  ? ', ' + selectedLanguages[1]
                  : ''
              }`
            : ''
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            selectLanguages([]);
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      <FilterItem
        isViewer={isViewer}
        content={
          riskChangeFilter === 'all'
            ? ''
            : `Risk Level Recently Changed:  ${riskChangeFilter.toUpperCase()}`
        }
        removeFilter={async () => {
          await new Promise<void>((resolve) => {
            setRiskChangeFilter('all');
            resolve();
          });

          setEnabledUpdateDashboard(true);
        }}
      />
      {showClearAll ? (
        !isViewer &&
        showClearAll && (
          <Button
            variant='text'
            color='primary'
            onClick={handleClearAllFilters}
            className={fontClasses.textXSRegular}
            style={{
              color: '#5EA1F2',
              height: 20,
              marginLeft: 8,
            }}
          >
            Clear All
          </Button>
        )
      ) : (
        <>No Filters</>
      )}
    </Grid>
  );
};
