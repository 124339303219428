import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { MouseEvent, FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/ClearOutlined';
import { GenreFilter } from '../sidebar/GenreFilter';
import { PublisherFilter } from '../sidebar/PublisherFilter';
import useSidebar from 'src/hooks/useSidebar';
import { Dashboard } from 'src/types';
import { DashboardFilter } from '../sidebar/DashboardFilter';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      background: '#fff',
      height: '100%',
      overflow: 'auto',
      width: 306,
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      zIndex: 100,
    },
    publishersContainer: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      padding: 5,
      marginTop: 4,
      gap: 12,
      minHeight: 50,
      cursor: 'pointer',
      overflow: 'auto',
      maxHeight: 350,
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        background: 'rgba(62, 145, 222, 0.5)',
        borderRadius: 20,
        padding: '0 8px',
        height: 24,
        color: '#3E91DE',
        fontSize: 13,
      },
    },
    selectedDashboard: {
      width: '100%',
      borderRadius: 3,
      border: '1px solid #C3C3C7',
      background: '#FFF',
      padding: 8,
      marginTop: 12,
      marginBottom: 4,
      cursor: 'pointer',
      position: 'relative',
      paddingRight: 32,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& > .arrowIcon': {
        position: 'absolute',
        top: '55%',
        right: 8,
        transform: 'translate(0, -50%)',
        '& > svg': {
          fontSize: 20,
        },
      },
    },
  })
);

interface SearchFilterSectionProps {
  genres: string[];
  selectedGenres: string[];
  excludedGenres: string[];
  publishers: string[];
  selectedPublishers: string[];
  setSelectedGenres: (items: string[]) => void;
  setExcludedGenres: (items: string[]) => void;
  setSelectedPublishers: (items: string[]) => void;
  dashboards: Dashboard[];
  selectedDashboardId: Nullable<string>;
  setSelectedDashboardId: (dashboard: Nullable<string>) => void;
  excludedPublishers: string[];
  setExcludedPublishers: (items: string[]) => void;
}

const SearchFilterSection: FC<SearchFilterSectionProps> = ({
  genres,
  selectedGenres,
  excludedGenres,
  publishers,
  selectedPublishers,
  setSelectedGenres,
  setExcludedGenres,
  setSelectedPublishers,
  dashboards,
  selectedDashboardId,
  setSelectedDashboardId,
  excludedPublishers,
  setExcludedPublishers,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('keyword');

  const [openPublishers, setOpenPublishers] = useState<boolean>(false);
  const [openGenresCollapse, setOpenGenresCollapse] = useState<boolean>(false);
  const [openSelections, setOpenSelections] = useState<string>('');
  const [openDashboardList, setOpenDashboardList] = useState<boolean>(false);

  const { isSmallSidebar } = useSidebar();

  const classes = useStyles();

  useEffect(() => {
    setSelectedGenres([]);
    setSelectedPublishers([]);
  }, [keyword]);

  const handleUnselectPublisher = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = selectedPublishers.filter((option) => option !== item);
    setSelectedPublishers(options);
  };

  const handleUnselectGenres = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = selectedGenres.filter((option) => option !== item);
    setSelectedGenres(options);
  };

  const handleUnexcludeGenres = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = excludedGenres.filter((option) => option !== item);
    setExcludedGenres(options);
  };

  return (
    <Box className={classes.container}>
      <Typography style={{ fontSize: 20, marginBottom: 16 }}>Search</Typography>
      <Grid container>
        <Grid
          container
          style={{
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'normal',
                paddingRight: 8,
                color: '#2A2A2E',
              }}
            >
              Dashboard
            </Typography>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenDashboardList(!openDashboardList);
              }}
              className={classes.selectedDashboard}
            >
              {selectedDashboardId
                ? dashboards.find((item) => item.id === selectedDashboardId)
                    ?.name
                : 'All Barometer Content'}
              <div className='arrowIcon'>
                {openDashboardList ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => {
            setOpenGenresCollapse(!openGenresCollapse);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Typography style={{ fontSize: 14, fontWeight: 'normal' }}>
            Genres
          </Typography>
          {openGenresCollapse ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </Grid>
        <Collapse
          in={openGenresCollapse}
          timeout='auto'
          unmountOnExit
          style={{ width: '100%' }}
        >
          <Grid
            container
            className={classes.publishersContainer}
            onClick={() => setOpenSelections((prev) => (prev ? '' : 'genre'))}
          >
            {selectedGenres.map((item) => (
              <div
                key={item}
                onClick={(e: MouseEvent) => handleUnselectGenres(e, item)}
              >
                <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                  {item}
                </Typography>
                <CancelIcon fontSize='inherit' />
              </div>
            ))}
            {excludedGenres.map((item) => (
              <div
                key={item}
                onClick={(e: MouseEvent) => handleUnexcludeGenres(e, item)}
                style={{
                  background: 'rgb(232 29 80 / 88%)',
                  color: 'rgba(0,0,0,0.87)',
                }}
              >
                <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                  {item}
                </Typography>
                <CancelIcon fontSize='inherit' />
              </div>
            ))}
          </Grid>
        </Collapse>
      </Grid>
      <Grid container>
        <Grid
          container
          item
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => {
            setOpenPublishers(!openPublishers);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Typography style={{ fontSize: 14, fontWeight: 'normal' }}>
            Publisher
          </Typography>
          {openPublishers ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Grid>
        <Collapse
          in={openPublishers}
          timeout='auto'
          unmountOnExit
          style={{ width: '100%' }}
        >
          <Grid
            container
            className={classes.publishersContainer}
            onClick={() =>
              setOpenSelections((prev) => (prev ? '' : 'publisher'))
            }
          >
            {selectedPublishers.map((item) => (
              <div
                key={item}
                onClick={(e: MouseEvent) => handleUnselectPublisher(e, item)}
              >
                <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                  {item}
                </Typography>
                <CancelIcon fontSize='inherit' />
              </div>
            ))}
          </Grid>
        </Collapse>
      </Grid>
      <GenreFilter
        genres={genres}
        selectedGenres={selectedGenres}
        excludedGenres={excludedGenres}
        isOpen={openSelections === 'genre'}
        isSmallSidebar={isSmallSidebar}
        handleClose={() => {
          setOpenSelections('');
        }}
        handleSave={(data) => {
          setSelectedGenres(data);
          setOpenSelections('');
        }}
        handleExclude={(data) => {
          setExcludedGenres(data);
          setOpenSelections('');
        }}
      />
      <PublisherFilter
        publishers={publishers}
        selectedPublishers={selectedPublishers}
        excludedPublishers={excludedPublishers}
        isOpen={openSelections === 'publisher'}
        isSmallSidebar={isSmallSidebar}
        handleClose={() => {
          setOpenSelections('');
        }}
        handleSave={(data) => {
          setSelectedPublishers(data);
          setOpenSelections('');
        }}
        handleExclude={(data) => {
          setExcludedPublishers(data);
          setOpenSelections('');
        }}
      />
      <DashboardFilter
        filters={dashboards}
        isOpen={openDashboardList}
        isSmallSidebar={isSmallSidebar}
        handleClose={() => {
          setOpenDashboardList(false);
        }}
        handleSelect={(item) => {
          setSelectedDashboardId(item ? item.id : null);
          setOpenDashboardList(false);
        }}
      />
    </Box>
  );
};

export default SearchFilterSection;
