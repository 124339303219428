import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { FilterTypes } from 'src/types/filter';
import { useTextStyles } from 'src/hooks/useTextStyles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      width: 400,
      padding: 24,
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    typeContainer: {
      padding: 16,
      border: '1px solid #C3C3C7',
      borderRadius: 8,
      cursor: 'pointer',
    },
    typeHeader: {
      display: 'flex',
      gap: 8,
    },
    exception: {
      borderRadius: 8,
      background: '#DFECFC',
      padding: '2px 8px',
    },
  })
);

interface FilterTypeContainerProps {
  selectedType: FilterTypes;
  exceptions: number[];
  onClick: (type: FilterTypes) => void;
}

const types = [
  { title: FilterTypes.GARM, description: '' },
  { title: FilterTypes.Podcasts, description: '' },
];

export const FilterTypeContainer = ({
  selectedType,
  exceptions,
  onClick,
}: FilterTypeContainerProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const handleSelect = (type: FilterTypes) => {
    onClick && onClick(type);
  };

  return (
    <div className={classes.container}>
      {types.map((type, index) => (
        <div
          key={type.title}
          className={classes.typeContainer}
          style={selectedType === type.title ? { borderColor: '#5EA1F2' } : {}}
          onClick={() => handleSelect(type.title)}
        >
          <div className={classes.typeHeader}>
            <Typography className={textClasses.smBold}>
              {type.title === 'GARM Score'
                ? 'Brand Suitability Filters'
                : type.title}
            </Typography>
            <div className={classes.exception}>
              <Typography
                className={textClasses.xsRegular}
                style={{ color: '#5EA1F2' }}
              >
                {exceptions[index]} Exceptions
              </Typography>
            </div>
          </div>
          <Typography
            className={textClasses.xsRegular}
            style={{ color: '#858591' }}
          >
            {type.description}
          </Typography>
        </div>
      ))}
    </div>
  );
};
