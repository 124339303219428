import { useState, useRef, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import useDashboardFilters from 'src/hooks/useDashboardFilters';
import Checkbox from 'src/components/Checkbox';
import Button from 'src/components/Button';
import { useClickAway } from 'src/hooks/useClickAway';
import { useStyles } from './AppBar.styles';

const All_Languages = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  {
    label: 'Arabic',
    value: 'ar-all',
    children: [
      { label: 'Arabic', value: 'ar' },
      { label: 'Arabic (Algeria)', value: 'ar-dz' },
      { label: 'Arabic (Bahrain)', value: 'ar-bh' },
      { label: 'Arabic (Egypt)', value: 'ar-eg' },
      { label: 'Arabic (Iraq)', value: 'ar-iq' },
      { label: 'Arabic (Jordan)', value: 'ar-jo' },
      { label: 'Arabic (Kuwait)', value: 'ar-kw' },
      { label: 'Arabic (Lebanon)', value: 'ar-lb' },
      { label: 'Arabic (Libya)', value: 'ar-ly' },
      { label: 'Arabic (Morocco)', value: 'ar-ma' },
      { label: 'Arabic (Oman)', value: 'ar-om' },
      { label: 'Arabic (Qatar)', value: 'ar-qa' },
      { label: 'Arabic (Saudi Arabia)', value: 'ar-sa' },
      { label: 'Arabic (Syria)', value: 'ar-sy' },
      { label: 'Arabic (Tunisia)', value: 'ar-tn' },
      { label: 'Arabic (U.A.E.)', value: 'ar-ae' },
      { label: 'Arabic (Yemen)', value: 'ar-ye' },
    ],
  },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  {
    label: 'Chinese',
    value: 'zh-all',
    children: [
      { label: 'Chinese', value: 'zh' },
      { label: 'Chinese (Hong Kong)', value: 'zh-hk' },
      { label: 'Chinese (PRC)', value: 'zh-cn' },
      { label: 'Chinese (Singapore)', value: 'zh-sg' },
      { label: 'Chinese (Taiwan)', value: 'zh-tw' },
    ],
  },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  {
    label: 'Dutch',
    value: 'nl-all',
    children: [
      { label: 'Dutch (Standard)', value: 'nl' },
      { label: 'Dutch (Belgium)', value: 'nl-be' },
    ],
  },
  {
    label: 'English',
    value: 'en-all',
    children: [
      { label: 'English', value: 'en' },
      { label: 'English (Australia)', value: 'en-au' },
      { label: 'English (Belize)', value: 'en-bz' },
      { label: 'English (Canada)', value: 'en-ca' },
      { label: 'English (Ireland)', value: 'en-ie' },
      { label: 'English (Jamaica)', value: 'en-jm' },
      { label: 'English (New Zealand)', value: 'en-nz' },
      { label: 'English (South Africa)', value: 'en-za' },
      { label: 'English (Trinidad)', value: 'en-tt' },
      { label: 'English (United Kingdom)', value: 'en-gb' },
      { label: 'English (United States)', value: 'en-us' },
    ],
  },
  { label: 'Estonian', value: 'et' },
  { label: 'Faeroese', value: 'fo' },
  { label: 'Farsi', value: 'fa' },
  { label: 'Finnish', value: 'fi' },
  {
    label: 'French',
    value: 'fr-all',
    children: [
      { label: 'French (Standard)', value: 'fr' },
      { label: 'French (Belgium)', value: 'fr-be' },
      { label: 'French (Canada)', value: 'fr-ca' },
      { label: 'French (Luxembourg)', value: 'fr-lu' },
      { label: 'French (Switzerland)', value: 'fr-ch' },
    ],
  },
  { label: 'Gaelic (Scotland)', value: 'gd' },
  {
    label: 'German',
    value: 'de-all',
    children: [
      { label: 'German (Standard)', value: 'de' },
      { label: 'German (Austria)', value: 'de-at' },
      { label: 'German (Liechtenstein)', value: 'de-li' },
      { label: 'German (Luxembourg)', value: 'de-lu' },
      { label: 'German (Switzerland)', value: 'de-ch' },
    ],
  },
  { label: 'Greek', value: 'el' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  {
    label: 'Italian',
    value: 'it-all',
    children: [
      { label: 'Italian (Standard)', value: 'it' },
      { label: 'Italian (Switzerland)', value: 'it-ch' },
    ],
  },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kurdish', value: 'ku' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Macedonian (FYROM)', value: 'mk' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Malaysian', value: 'ms' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Norwegian (Bokmål)', value: 'nb' },
  { label: 'Norwegian (Nynorsk)', value: 'nn' },
  { label: 'Polish', value: 'pl' },
  {
    label: 'Portuguese',
    value: 'pt-all',
    children: [
      { label: 'Portuguese (Brazil)', value: 'pt-br' },
      { label: 'Portuguese (Portugal)', value: 'pt' },
    ],
  },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Rhaeto-Romanic', value: 'rm' },
  {
    label: 'Romanian',
    value: 'ro-all',
    children: [
      { label: 'Romanian', value: 'ro' },
      { label: 'Romanian (Republic of Moldova)', value: 'ro-md' },
    ],
  },
  {
    label: 'Russian',
    value: 'ru-all',
    children: [
      { label: 'Russian', value: 'ru' },
      { label: 'Russian (Republic of Moldova)', value: 'ru-md' },
    ],
  },
  { label: 'Serbian', value: 'sr' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Sorbian', value: 'sb' },
  {
    label: 'Spanish',
    value: 'es-all',
    children: [
      { label: 'Spanish (Argentina)', value: 'es-ar' },
      { label: 'Spanish (Bolivia)', value: 'es-bo' },
      { label: 'Spanish (Chile)', value: 'es-cl' },
      { label: 'Spanish (Colombia)', value: 'es-co' },
      { label: 'Spanish (Costa Rica)', value: 'es-cr' },
      { label: 'Spanish (Dominican Republic)', value: 'es-do' },
      { label: 'Spanish (Ecuador)', value: 'es-ec' },
      { label: 'Spanish (El Salvador)', value: 'es-sv' },
      { label: 'Spanish (Guatemala)', value: 'es-gt' },
      { label: 'Spanish (Honduras)', value: 'es-hn' },
      { label: 'Spanish (Mexico)', value: 'es-mx' },
      { label: 'Spanish (Nicaragua)', value: 'es-ni' },
      { label: 'Spanish (Panama)', value: 'es-pa' },
      { label: 'Spanish (Paraguay)', value: 'es-py' },
      { label: 'Spanish (Peru)', value: 'es-pe' },
      { label: 'Spanish (Puerto Rico)', value: 'es-pr' },
      { label: 'Spanish (Spain)', value: 'es' },
      { label: 'Spanish (Uruguay)', value: 'es-uy' },
      { label: 'Spanish (Venezuela)', value: 'es-ve' },
    ],
  },
  {
    label: 'Swedish',
    value: 'sv-all',
    children: [
      { label: 'Swedish', value: 'sv' },
      { label: 'Swedish (Finland)', value: 'sv-fi' },
    ],
  },
  { label: 'Thai', value: 'th' },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Tswana', value: 'tn' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukrainian', value: 'ua' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Venda', value: 've' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'ji' },
  { label: 'Zulu', value: 'zu' },
];

interface LanguageFilterProps {
  isOpen: boolean;
  isSmallSidebar?: boolean;
  handleClose: () => void;
  selectedLanguages: string[];
  excludedLanguages: string[];
  setSelectedLanguages: (languages: string[]) => void;
  setExcludedLanguages: (languages: string[]) => void;
}

export const LanguageFilter = ({
  isOpen,
  isSmallSidebar = false,
  handleClose,
  selectedLanguages,
  excludedLanguages,
  setSelectedLanguages,
  setExcludedLanguages,
}: LanguageFilterProps) => {
  const classes = useStyles();
  const ref = useRef<any>();
  const [data, setData] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [isExclude, setIsExclude] = useState(false);

  useClickAway({ ref, handleClickAway: handleClose });

  const formatTreeData = useCallback(
    (tree: any, isClear?: boolean): any[] => {
      const temp: any[] = [];
      tree
        .sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
        .forEach((item: any) => {
          const children =
            item.children && item.children.length
              ? formatTreeData(item.children, isClear)
              : undefined;
          const isParent = item.children && !!item.children.length;
          temp.push({
            ...item,
            isChecked: isClear
              ? false
              : isParent
              ? item.children.every((language: any) =>
                  isExclude
                    ? excludedLanguages.includes(language.value)
                    : selectedLanguages.includes(language.value)
                )
              : isExclude
              ? excludedLanguages.includes(item.value)
              : selectedLanguages.includes(item.value),
            isExpanded: false,
            isShow: true,
            children,
          });
        });
      return temp;
    },
    [selectedLanguages, excludedLanguages]
  );

  const filterTreeData = useCallback(
    (tree: any, key: string, isParentShow = false): any[] => {
      const temp: any[] = [];
      tree.forEach((item: any) => {
        const isShow = item.label.toLowerCase().includes(key.toLowerCase());
        const children =
          item.children && item.children.length
            ? filterTreeData(item.children, key, isShow || isParentShow)
            : [];

        temp.push({
          ...item,
          isShow:
            key === '' ||
            isShow ||
            isParentShow ||
            (item.children &&
            item.children.length &&
            item.children.some((language: any) =>
              language.label.toLowerCase().includes(key.toLowerCase())
            )
              ? true
              : false),
          children,
        });
      });
      return temp;
    },
    []
  );

  const countShowData = useCallback((tree: any): number => {
    let counter = 0;
    tree.forEach((item: any) => {
      const childCounter =
        item.children && item.children.length
          ? countShowData(item.children)
          : 0;
      const currentCounter = childCounter ? childCounter : item.isShow ? 1 : 0;
      counter = counter + currentCounter;
    });
    return counter;
  }, []);

  useEffect(() => {
    setData(filterTreeData(data, searchKey || ''));
  }, [searchKey]);

  const saveSelection = useCallback((tree: any[]) => {
    let temp: string[] = [];
    tree.forEach((item: any) => {
      if (item.children && item.children.length) {
        const children = saveSelection(item.children);
        temp = [...temp, ...children];
      } else if (item.isChecked) {
        temp.push(item.value);
      }
    });
    return temp;
  }, []);

  useEffect(() => {
    if (isOpen) {
      setSearchKey('');
      setData(formatTreeData(All_Languages));
    }
  }, [isOpen, isExclude]);

  const updateTreeData = useCallback(
    (
      tree: any[],
      value: string,
      property: string,
      propertyValue: string | boolean
    ): any[] => {
      const temp: any[] = [...tree];
      tree.forEach((node, index) => {
        if (node.value === value) {
          temp[index][property] = propertyValue;
          if (
            property === 'isChecked' &&
            node.children &&
            node.children.length
          ) {
            const children = node.children.map((child: any) => {
              updateTreeData(
                node.children,
                child.value,
                property,
                propertyValue
              );
              return {
                ...child,
                isChecked: propertyValue,
              };
            });
            temp[index].children = children;
          }
        } else {
          if (node.children && node.children.length) {
            const children = updateTreeData(
              node.children,
              value,
              property,
              propertyValue
            );
            let isChecked = node.isChecked;
            if (property === 'isChecked') {
              isChecked = children.every((child) => child.isChecked);
            }
            temp[index] = {
              ...node,
              children,
              isChecked,
            };
          }
        }
      });
      return temp;
    },
    []
  );

  const showCategories = (categories: any[]) => {
    return categories.map((item: any) => {
      const hasChildren = !!item.children?.length;
      if (!item.isShow) {
        return null;
      }

      return (
        <div key={item.value}>
          <div className={classes.selectOption}>
            {hasChildren ? (
              item.isExpanded ? (
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setData(
                      updateTreeData(data, item.value, 'isExpanded', false)
                    );
                  }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  onClick={() => {
                    setData(
                      updateTreeData(data, item.value, 'isExpanded', true)
                    );
                  }}
                />
              )
            ) : (
              <div style={{ width: 16 }} />
            )}
            <Checkbox
              name={item.value}
              label=''
              checked={item.isChecked}
              noPadding={true}
              onChange={() => {
                if (item.isChecked) {
                  setData(updateTreeData(data, item.value, 'isChecked', false));
                } else {
                  setData(updateTreeData(data, item.value, 'isChecked', true));
                }
              }}
              disabled={
                isExclude ? false : excludedLanguages.includes(item.value)
              }
            />
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 'normal',
                color:
                  !isExclude && excludedLanguages.includes(item.value)
                    ? 'rgba(0,0,0,0.45)'
                    : 'rgba(0,0,0,0.87)',
              }}
            >
              {item.label} {hasChildren ? <>({item.children?.length})</> : null}
            </Typography>
          </div>
          <div className={classes.selectChildren}>
            {hasChildren && item.isExpanded && showCategories(item.children)}
          </div>
        </div>
      );
    });
  };

  const searchResultCounter = countShowData(data);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classes.selectionContainer}
      style={{ left: isSmallSidebar ? 372 : 552 }}
      ref={ref}
    >
      <div className={classes.selectionMain}>
        <div className={classes.selectionHeader}>
          <Typography
            style={{
              fontSize: 16,
              color: 'gray',
              fontWeight: 'normal',
              display: 'flex',
              alignItems: 'center',
              gap: 6,
            }}
          >
            <span>Find podcasts by their languages</span>
          </Typography>
          <div>
            <div className={classes.buttonGroup}>
              <Button
                title='Include'
                color={isExclude ? 'secondary' : 'primary'}
                variant={isExclude ? 'outlined' : 'contained'}
                fontSize='sm'
                height={30}
                onClick={() => {
                  setIsExclude(false);
                }}
              />
              <Button
                title='Exclude'
                color={isExclude ? 'primary' : 'secondary'}
                variant={isExclude ? 'contained' : 'outlined'}
                fontSize='sm'
                height={30}
                onClick={() => {
                  setIsExclude(true);
                }}
              />
            </div>
          </div>
          <div className={classes.searchContainer}>
            <SearchIcon />
            <input
              placeholder='Search languages...'
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'normal',
            }}
          >
            {!searchKey
              ? `Showing ${searchResultCounter} results`
              : `Showing ${searchResultCounter} results for ${searchKey}`}
          </Typography>
        </div>
        <div className={classes.selectionOptions}>{showCategories(data)}</div>
      </div>
      <div className={classes.selctionActions}>
        <Button
          title='Clear Selection'
          color='primary'
          variant='text'
          fontSize='sm'
          height={30}
          onClick={() => {
            setData(formatTreeData(All_Languages, true));
            setSearchKey('');
          }}
        />
        <Button
          title={isExclude ? 'Exclude Languages' : 'Select Languages'}
          color='primary'
          fontSize='sm'
          height={30}
          onClick={() => {
            const selectedData = saveSelection(data);
            if (isExclude) {
              setExcludedLanguages(selectedData);
              setSelectedLanguages(
                selectedLanguages.filter((item) => !selectedData.includes(item))
              );
            } else {
              setSelectedLanguages(selectedData);
            }

            handleClose();
            setSearchKey('');
            setData([]);
          }}
        />
      </div>
    </div>
  );
};
