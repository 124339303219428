import { FC, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import brandSafetyImg from 'src/assets/landing/leverageAI/brandSafety.png';
import contextualTargetingImg from 'src/assets/landing/leverageAI/contextualTargeting.png';
import hostIntelligenceImg from 'src/assets/landing/leverageAI/hostIntelligence.png';
import garmBrand from 'src/assets/brands/garm.png';
import iABMemberBrand from 'src/assets/brands/iABMember.png';
import iABTechLabMemberBrand from 'src/assets/brands/iABTechLabMember.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      background: '#EFEDEB',
      padding: '100px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: 36,
    },
    mainContainer: {
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      gap: 32,

      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
        padding: '0 100px',
      },

      '& > div': {
        flex: 1,
      },
    },
    title: {
      fontWeight: 300,
      fontSize: 32,
      lineHeight: '40px',
      color: '#202226',
      padding: 0,
      margin: 0,
      maxWidth: 520,
      marginBottom: 45,

      '@media screen and (min-width: 768px)': {
        fontSize: 48,
        lineHeight: '56px',
        marginBottom: 70,
      },
    },
    description: {
      fontWeight: 300,
      fontSize: 25,
      lineHeight: '40px',
      color: '#545454',
      padding: 0,
      margin: 0,
    },
    logo: {
      width: 'calc(100% + 100px)',
      marginLeft: -100,
    },
    stepTitle: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '24px',
      color: '#545454',
      textTransform: 'uppercase',
      margin: 0,
      position: 'relative',
      cursor: 'pointer',

      '@media screen and (min-width: 768px)': {
        fontWeight: 500,
        fontSize: 20,
      },
    },
    activeStep: {
      color: '#A172D7',
      marginLeft: 30,

      '&::before': {
        content: '"→"',
        color: '#A172D7',
        fontWeight: 700,
        fontSize: 20,
        paddingRight: 8,
        position: 'absolute',
        right: '100%',
      },
    },
    stepContainer: {
      marginTop: 22,
      marginBottom: 110,

      '@media screen and (min-width: 768px)': {
        marginLeft: 40,
        marginTop: 32,
        marginBottom: 80,
      },
    },
    stepContent: {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px',
      color: '#545454',

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '48px',
      },
    },
    stepImg: {
      height: 48,

      '@media screen and (min-width: 768px)': {
        height: 72,
      },
    },
    mobileImg: {
      background: '#E8E5E2',
      borderRadius: 2,
      width: '100%',
      margin: '22px 0',
      padding: '24px 12px',
      '@media screen and (min-width: 768px)': {
        display: 'none',
      },

      '& > img': {
        width: '100%',
      },
    },
    desktopImg: {
      background: '#E8E5E2',
      borderRadius: 2,
      width: '100%',
      padding: '80px 50px',
      display: 'none',
      position: 'absolute',
      top: -120,
      right: -100,

      '@media screen and (min-width: 768px)': {
        display: 'block',
      },

      '& > img': {
        width: '100%',
      },
    },
  })
);

const LeverageAI: FC = () => {
  const classes = useStyles();
  const [step, setStep] = useState(0);

  const stepContents = [
    {
      title: 'Brand Safety & Suitability',
      description: (
        <Box
          display='flex'
          flexDirection='column'
          gridGap='28px'
          className={classes.stepContainer}
        >
          <Typography className={classes.stepContent}>
            - IAB and TechLab Member
            <br />
            - Enforcing brand standards using context not keywords
            <br />- First to market Trade Desk integration enabling targeting
            podcasts for brand suitability
          </Typography>
        </Box>
      ),
      img: brandSafetyImg,
    },
    {
      title: 'Contextual Targeting',
      description: (
        <Box
          display='flex'
          flexDirection='column'
          gridGap='28px'
          className={classes.stepContainer}
        >
          <Typography className={classes.stepContent}>
            Efficiently discover the most relevant content for your campaign!
            <br />
            Easily scan through thousands of podcasts by publisher, IAB
            category, and more.
          </Typography>
          <Box display='flex' alignItems='center' gridGap='64px'>
            <img
              src={iABMemberBrand}
              alt='iABMemberBrand'
              className={classes.stepImg}
            />
          </Box>
        </Box>
      ),
      img: contextualTargetingImg,
    },
    {
      title: 'Host Intelligence',
      description: (
        <Box
          display='flex'
          flexDirection='column'
          gridGap='28px'
          className={classes.stepContainer}
        >
          <Typography className={classes.stepContent}>
            Keep up with news about creators from across 50,000 sources so you
            never miss anything critical.
          </Typography>
        </Box>
      ),
      img: hostIntelligenceImg,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div>
          <Typography className={classes.title}>
            Leverage AI to optimize campaigns responsibly
          </Typography>
        </div>
      </div>
      <Box className={classes.mainContainer} minHeight={600}>
        <div>
          {stepContents.map((item, index) => (
            <Box key={item.title} marginTop='16px'>
              <Typography
                className={classNames(
                  classes.stepTitle,
                  index === step && classes.activeStep
                )}
                onClick={() => setStep(index)}
              >
                {item.title}
              </Typography>
              {index === step && (
                <div className={classes.mobileImg}>
                  <img src={item.img} alt='bug' />
                </div>
              )}
              {index === step && item.description}
            </Box>
          ))}
        </div>
        <Box position='relative'>
          <div className={classes.desktopImg}>
            <img src={stepContents[step].img} alt='bug' />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default LeverageAI;
