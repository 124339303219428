import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  styled,
  Typography,
  Collapse,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  ChangeEvent,
  MouseEventHandler,
  MouseEvent,
  FC,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import CancelIcon from '@material-ui/icons/ClearOutlined';

import TextInput from 'src/components/TextInput';
import InfoTooltip from 'src/components/InfoTooltip';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import { Theme } from 'src/theme/types/createPalette';
import { useDashboardData } from 'src/containers/dashboard/garm/hooks';
import useSidebar from 'src/hooks/useSidebar';
import {
  ScoreFilterType,
  ScoreExceptionTypes,
  BrandCustomFilter,
} from 'src/types/filter';
import { NewDashboardButton } from 'src/components/dashboard/MenuRow/MenuRow';
import { getGarmTitle } from 'src/utils/category';
import { SelfScoringProcessTypes } from 'src/types';

import ColorFilterElement from './color';
import { Title } from './Common';
import DropDownComponent from './dropdown';
import RangeSelectionComponent from './range-selection';
import { Border } from './styles';
import TagCloudInput from './tag-cloud';
import TriSelector from './tri-selector';
import Button from 'src/components/Button';
import { useDashboardStyles } from 'src/containers/dashboard/styles';
import { SelfScoringModal } from 'src/modals/SelfScoringModal';
import { ReactComponent as RefreshIcon } from 'src/assets/icons/refresh.svg';
import { LANGUAGE_LIST } from 'src/utils';
import { SliderContainer } from './range-selection/RangeSelection';

type FilterSectionVariant =
  | 'dropdown'
  | 'color'
  | 'range'
  | 'tag-cloud'
  | 'tri';

const COMPONENT_SPECIFICATION: { [type in FilterSectionVariant]: any } = {
  dropdown: DropDownComponent,
  color: ColorFilterElement,
  range: RangeSelectionComponent,
  'tag-cloud': TagCloudInput,
  tri: TriSelector,
};

interface FilterSubSectionProps {
  border?: boolean;
  title?: string;
  variant: FilterSectionVariant;
  onChange: (event: ChangeEvent<any>, n: number | number[]) => void;
  value?: number | number[];
  filterType?: string;
}

const FilterSubSection: FC<FilterSubSectionProps> = ({
  border,
  title,
  variant,
  onChange,
  value,
  filterType,
}) => {
  const ActiveComponent = COMPONENT_SPECIFICATION[variant];
  const valueArray = (value as number[]) || [];
  return (
    <>
      {title && <Title>{title}</Title>}
      {filterType === 'audience' ? (
        <RangeSelectionComponent
          onChange={onChange}
          value={
            [valueArray[0], valueArray[1] > 5 ? 5 : valueArray[1]] || [0, 5]
          }
          axisLabels={{
            0: '0',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
          }}
          min={0}
          type={6}
          step={0.5}
        />
      ) : (
        <ActiveComponent
          onChange={onChange}
          value={value}
          axisLabels={
            filterType === 'tolerance'
              ? { 0: 'N/A', 1: 'High Tolerance', 3: 'Low Tol.' }
              : null
          }
        />
      )}

      {border && <Border />}
    </>
  );
};

interface DashboardMenuProps {
  isDeleteDisabled: boolean;
  onDelete: VoidFunction;
  onEdit: VoidFunction;
  onMakePrimary: VoidFunction;
}

const DashboardMenu: FC<DashboardMenuProps> = ({
  isDeleteDisabled,
  onDelete,
  onEdit,
  onMakePrimary,
}) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  const handleEdit = () => {
    onEdit();
    handleClose();
  };

  const handleMakePrimary = () => {
    onMakePrimary();
    handleClose();
  };

  const handleOpenMenu: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MoreVertIcon htmlColor='#757575' style={{ fontSize: 24 }} />
      </IconButton>

      <Menu
        id='dashboard-menu'
        aria-labelledby='dashboard-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem disabled={isDeleteDisabled} onClick={handleDelete}>
          Delete
        </MenuItem>
        <MenuItem onClick={handleMakePrimary}>Make Primary</MenuItem>
      </Menu>
    </>
  );
};

export const DashboardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 20,
  lineHeight: '24px',
  fontWeight: 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipLink: {
      color: theme.palette.yellow.main,
    },
    sourcesTitle: {
      marginTop: 15,
      '& > svg': {
        '& > path': {
          color: '#757575',
        },
      },
    },
    riskChangeFilter: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 3,
      borderColor: '#3E91DE',
      borderRadius: 20,
      display: 'flex',
      marginBottom: 12,
      overflow: 'hidden',
      '& > div': {
        flex: 1,
        textAlign: 'center',
        fontSize: 15,
        fontWeight: 'normal',
        padding: 1,
        cursor: 'pointer',
        textTransform: 'capitalize',
        '&:hover': {
          background: '#3E91DE30',
        },
        '&:first-child': {
          borderRight: '3px solid #3E91DE',
        },
        '&:last-child': {
          borderLeft: '3px solid #3E91DE',
        },
      },
    },
    publishersContainer: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      padding: 5,
      marginTop: 4,
      gap: 12,
      minHeight: 50,
      cursor: 'pointer',
      overflow: 'auto',
      maxHeight: 350,
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        background: 'rgba(62, 145, 222, 0.5)',
        borderRadius: 20,
        padding: '0 8px',
        height: 24,
        color: '#3E91DE',
        fontSize: 13,
      },
    },
    clearBtn: {
      fontWeight: 500,
      fontSize: 12,
      color: '#5EA1F2',
      background: 'transparent',
      padding: 0,
      minWidth: 'auto',
    },
    garmScoreCat: {
      position: 'relative',
    },
    newBrandIcon: {
      width: 24,
      height: 24,
      backgroundColor: '#5EA1F2',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    selectedBrandFilter: {
      width: '100%',
      borderRadius: 3,
      border: '1px solid #C3C3C7',
      background: '#FFF',
      padding: 8,
      marginTop: 12,
      marginBottom: 4,
      cursor: 'pointer',
      position: 'relative',
      paddingRight: 32,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& > .arrowIcon': {
        position: 'absolute',
        top: '55%',
        right: 8,
        transform: 'translate(0, -50%)',
        '& > svg': {
          fontSize: 20,
        },
      },
    },
    filterBtn: {
      fontWeight: 500,
      fontSize: 20,
      color: 'white',
      minWidth: 'auto',
      width: '100%',
      borderRadius: 24,
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      marginTop: 12,
    },
    content: {
      height: 'calc(100vh - 154px)',
      overflow: 'auto',
      padding: '0 16px',
      marginLeft: '-16px',
      marginRight: '-16px',

      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  })
);

interface DashboardFilterSectionProps {
  openSelection: boolean;
  openIABCategories: boolean;
  openGenres: boolean;
  openCustomFilters: boolean;
  openLanguages: boolean;
  openCustomTags: boolean;
  setOpenSelection: (flag: boolean) => void;
  setOpenIABCategories: (flag: boolean) => void;
  setOpenGenres: (flag: boolean) => void;
  setOpenCustomFilters: (flag: boolean) => void;
  setOpenLanguages: (flag: boolean) => void;
  onDelete: (dashboardId: string) => void;
  onMakePrimary: (dashboardId: string) => void;
  setOpenCustomTags: (flag: boolean) => void;
  garmSourceFiltersTemp: string[];
  setGarmSourceFiltersTemp: (value: string[]) => void;
  dashboardId: Nullable<string>;
  dashboardName: string;
  changeDashboardName: (name: string) => void;
  audienceTemp?: number | number[];
  setAudienceTemp: (temp: any) => void;
  selectedIABCategoriesTemp: any[];
  setSelectedIABCategoriesTemp: (temp: any[]) => void;
  selectedPublishersTemp: string[];
  setSelectedPublishersTemp: (temp: string[]) => void;
  excludedPublishersTemp: string[];
  setExcludedPublishersTemp: (temp: string[]) => void;
  selectedGenresTemp: string[];
  setSelectedGenresTemp: (temp: string[]) => void;
  excludedGenresTemp: string[];
  setExcludedGenresTemp: (temp: string[]) => void;
  selectedCustomTagsTemp: string[];
  setSelectedCustomTagsTemp: (temp: string[]) => void;
  excludedCustomTagsTemp: string[];
  setExcludedCustomTagsTemp: (temp: string[]) => void;
  selectedLanguagesTemp: string[];
  setSelectedLanguagesTemp: (temp: string[]) => void;
  excludedLanguagesTemp: string[];
  setExcludedLanguagesTemp: (temp: string[]) => void;
  selectedSelfScoring?: SelfScoringProcessTypes;
  selectedCustomFilterTemp: Nullable<BrandCustomFilter>;
  setSelectedCustomFilterTemp: (temp: Nullable<BrandCustomFilter>) => void;
  setSelectedCustomFilterId: (item: string) => void;
  riskFilterEnabled: (key: string) => boolean;
  handleFilterTempChange: (key: string, n: number | number[]) => void;
  getFilterTempValue: (key: string) => void;
  electionRangeTemp: number[];
  setElectionRangeTemp: (temp: number[]) => void;
  riskChangeFilterTemp: string;
  setRiskChangeFilterTemp: (temp: string) => void;
  handleApplyFilter: () => void;
  enableApplyBtn: boolean;
}

const DashboardFilterSection: FC<DashboardFilterSectionProps> = ({
  openSelection,
  openIABCategories,
  openGenres,
  openCustomFilters,
  openLanguages,
  openCustomTags,
  onDelete,
  onMakePrimary,
  setOpenSelection,
  setOpenIABCategories,
  setOpenLanguages,
  setOpenGenres,
  setOpenCustomFilters,
  setOpenCustomTags,
  garmSourceFiltersTemp,
  setGarmSourceFiltersTemp,
  dashboardId,
  dashboardName,
  changeDashboardName,
  audienceTemp,
  setAudienceTemp,
  selectedIABCategoriesTemp,
  setSelectedIABCategoriesTemp,
  selectedPublishersTemp,
  setSelectedPublishersTemp,
  excludedPublishersTemp,
  setExcludedPublishersTemp,
  selectedGenresTemp,
  setSelectedGenresTemp,
  excludedGenresTemp,
  setExcludedGenresTemp,
  selectedCustomTagsTemp,
  setSelectedCustomTagsTemp,
  excludedCustomTagsTemp,
  setExcludedCustomTagsTemp,
  selectedLanguagesTemp,
  setSelectedLanguagesTemp,
  excludedLanguagesTemp,
  setExcludedLanguagesTemp,
  selectedSelfScoring,
  selectedCustomFilterTemp,
  setSelectedCustomFilterTemp,
  setSelectedCustomFilterId,
  riskFilterEnabled,
  handleFilterTempChange,
  getFilterTempValue,
  electionRangeTemp,
  setElectionRangeTemp,
  riskChangeFilterTemp,
  setRiskChangeFilterTemp,
  handleApplyFilter,
  enableApplyBtn,
}) => {
  const history = useHistory();
  const [openCats, setOpenCats] = useState<boolean>(false);
  const [openPublishers, setOpenPublishers] = useState<boolean>(false);
  const [openIABFilters, setOpenIABFilters] = useState<boolean>(false);
  const [openGenresCollapse, setOpenGenresCollapse] = useState<boolean>(false);
  const [openCustomTagsCollapse, setOpenCustomTagsCollapse] =
    useState<boolean>(false);
  const [openLanguagesCollapse, setOpenLanguagesCollapse] =
    useState<boolean>(false);

  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isGettingShows, setIsGettingShows] = useState<boolean>(false);
  const [scoringPodcast, setScoringPodcast] = useState<boolean>(false);

  const classes = useStyles();
  const commonClasses = useDashboardStyles();

  const { setSecondSidebarOpen } = useSidebar();

  const { fetchFilteredGarmShowNames } = useDashboardData();

  const { dashboardCategories } = useDashboardCategories();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = () => {
    if (dashboardId) {
      onDelete(dashboardId);
    }
  };

  const handleMakePrimary = () => {
    if (dashboardId) {
      onMakePrimary(dashboardId);
    }
  };

  const handleFinishEditing = () => {
    setIsEditing(false);
  };

  const handleExpand = () => {
    setIsExpanded((previous) => !previous);
  };

  const handleUnselectPublisher = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = selectedPublishersTemp.filter((option) => option !== item);
    setSelectedPublishersTemp(options);
  };

  const handleUnexcludePublisher = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = excludedPublishersTemp.filter((option) => option !== item);
    setExcludedPublishersTemp(options);
  };

  const handleUnselectGenres = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = selectedGenresTemp.filter((option) => option !== item);
    setSelectedGenresTemp(options);
  };

  const handleUnexcludeGenres = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = excludedGenresTemp.filter((option) => option !== item);
    setExcludedGenresTemp(options);
  };

  const handleUnselectCustomTags = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = selectedCustomTagsTemp.filter((option) => option !== item);
    setSelectedCustomTagsTemp(options);
  };

  const handleUnexcludeCustomTags = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = excludedCustomTagsTemp.filter((option) => option !== item);
    setExcludedCustomTagsTemp(options);
  };

  const handleUnselectLanguages = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = selectedLanguagesTemp.filter((option) => option !== item);
    setSelectedLanguagesTemp(options);
  };

  const handleUnexcludeLanguages = (e: MouseEvent, item: string) => {
    e.stopPropagation();
    const options = excludedLanguagesTemp.filter((option) => option !== item);
    setExcludedLanguagesTemp(options);
  };

  const removeSelectedIABCategories = (categories: any[], selection: any) => {
    let temp = [selection];
    if (selection.parentId) {
      const parent = categories.find(
        (option) => option.id === selection.parentId
      );
      if (parent) {
        temp = [...temp, ...removeSelectedIABCategories(categories, parent)];
      }
    }
    return temp;
  };

  const handleUnselectIABCategories = (e: MouseEvent, selection: any) => {
    e.stopPropagation();
    const removedCategories = removeSelectedIABCategories(
      selectedIABCategoriesTemp,
      selection
    );
    const options = selectedIABCategoriesTemp.filter(
      (option) =>
        !removedCategories.find((category) => category.id === option.id)
    );
    setSelectedIABCategoriesTemp(options);
  };

  const handleImport = () => {
    setScoringPodcast(true);
  };

  const handleGetShows = async () => {
    setIsGettingShows(true);
    try {
      const names = await fetchFilteredGarmShowNames();
      setGarmSourceFiltersTemp(names.sort());
    } finally {
      setIsGettingShows(false);
    }
  };

  const handleCreateNewBrand = () => {
    setSecondSidebarOpen(false);
    history.push('/settings/standard-filters?create=true');
  };

  return (
    <FilterContainer>
      <Grid container alignItems='center'>
        <Grid item xs={10}>
          {isEditing ? (
            <TextInput
              value={dashboardName}
              onChange={changeDashboardName}
              onEnter={handleFinishEditing}
            />
          ) : (
            <DashboardTitle
              variant='h5'
              style={{ fontSize: 16, fontWeight: 'normal' }}
            >
              {dashboardName}
            </DashboardTitle>
          )}
        </Grid>
        <Grid item container xs={2} justifyContent='flex-end'>
          <DashboardMenu
            isDeleteDisabled={!dashboardId}
            onDelete={handleDelete}
            onEdit={handleEdit}
            onMakePrimary={handleMakePrimary}
          />
        </Grid>
      </Grid>
      <Box className={classes.content}>
        <Grid container>
          <Grid
            container
            className={classes.sourcesTitle}
            alignItems='center'
            justifyContent='space-between'
          >
            <Box display='flex' alignItems='center' gridGap='4px'>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                }}
              >
                Podcasts
              </Typography>
              {selectedSelfScoring?.isRunning && (
                <InfoTooltip
                  title={`Processing Scoring Request: ${selectedSelfScoring.progress}%`}
                >
                  <RefreshIcon width={14} height={14} />
                </InfoTooltip>
              )}
            </Box>
            <Box display='flex' alignItems='center' gridGap='8px'>
              <Button
                variant='text'
                onClick={() => handleImport()}
                height='18px'
                fontSize='sm'
                className={classes.clearBtn}
              >
                Import
              </Button>
              <Button
                onClick={() => handleGetShows()}
                height='18px'
                fontSize='sm'
                className={classes.clearBtn}
              >
                Get Shows
              </Button>
              <Button
                variant='text'
                onClick={() => setGarmSourceFiltersTemp([])}
                height='18px'
                fontSize='sm'
                className={classes.clearBtn}
              >
                Clear
              </Button>
              {isExpanded ? (
                <KeyboardArrowUpIcon cursor='pointer' onClick={handleExpand} />
              ) : (
                <KeyboardArrowDownIcon
                  cursor='pointer'
                  onClick={handleExpand}
                />
              )}
            </Box>
          </Grid>

          <TagCloudInput
            value={garmSourceFiltersTemp}
            onChange={setGarmSourceFiltersTemp}
            isExpanded={isExpanded}
            isLoading={isGettingShows}
          />
        </Grid>
        <Grid
          container
          style={{
            marginTop: 36,
            marginBottom: 16,
          }}
        >
          <Grid container alignItems='center'>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 'normal',
              }}
            >
              Podcast Rating
            </Typography>
            <span
              role='img'
              aria-label='star'
              style={{ fontSize: 14, marginLeft: 4 }}
            >
              {String.fromCodePoint(0x2b50)}
            </span>
          </Grid>
          <FilterSubSection
            variant='range'
            onChange={(_e, v) => {
              setAudienceTemp(v);
            }}
            value={audienceTemp}
            filterType='audience'
          />
        </Grid>
        <Grid
          container
          style={{
            marginTop: 40,
            marginBottom: 30,
          }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            onClick={() => {
              setOpenIABFilters(!openIABFilters);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={'/images/iab/iabLogo.png'}
              height='34px'
              alt='iab_memberseal'
            />
            {openIABFilters ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Grid>
          <Collapse
            in={openIABFilters}
            timeout='auto'
            unmountOnExit
            style={{ width: '100%' }}
          >
            <Grid
              container
              className={classes.publishersContainer}
              onClick={() => setOpenIABCategories(!openIABCategories)}
            >
              {selectedIABCategoriesTemp.map((item) =>
                item.children && item.children.length ? null : (
                  <div
                    key={item.id}
                    onClick={(e: MouseEvent) =>
                      handleUnselectIABCategories(e, item)
                    }
                  >
                    <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                      {item.name}
                    </Typography>
                    <CancelIcon fontSize='inherit' />
                  </div>
                )
              )}
            </Grid>
          </Collapse>
        </Grid>
        <Grid
          container
          style={{
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'normal',
                paddingRight: 8,
                color: '#2A2A2E',
              }}
            >
              Brand Standard Filters
            </Typography>
            <div
              className={classes.newBrandIcon}
              onClick={handleCreateNewBrand}
            >
              <AddIcon fontSize='small' style={{ color: '#fff' }} />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenCustomFilters(!openCustomFilters);
              }}
              className={classes.selectedBrandFilter}
            >
              {selectedCustomFilterTemp?.name || 'None'}
              <div className='arrowIcon'>
                {openCustomFilters ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
            </div>
            {selectedCustomFilterTemp && (
              <Button
                color='primary'
                disableElevation
                variant='text'
                size='small'
                onClick={() => {
                  setSelectedCustomFilterId('');
                  setSelectedCustomFilterTemp(null);
                }}
              >
                Hide All Exceptions
              </Button>
            )}
          </Grid>
        </Grid>
        {dashboardCategories.map((cat) => (
          <Grid
            container
            style={
              cat.enabled
                ? {
                    background: '#F0F0F1',
                    padding: 8,
                    borderRadius: 8,
                    marginBottom: 24,
                  }
                : { opacity: 0.6, marginBottom: 24 }
            }
            key={cat.title}
          >
            <Grid
              container
              item
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              onClick={() => setOpenCats(!openCats)}
              style={{ cursor: 'pointer' }}
            >
              <Typography
                className={commonClasses.textSMBold}
                style={{ color: '#686874' }}
              >
                {cat.title === 'GARM Scores' ? (
                  <>
                    <span style={{ color: '#EA3344' }}>GARM </span>
                    <span>Scores</span>
                  </>
                ) : (
                  <span>{cat.title}</span>
                )}
              </Typography>
              {openCats ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Grid>
            <Collapse in={openCats} timeout='auto' unmountOnExit>
              {(cat.entries as any[]).map((entry: any, index: number) => (
                <Grid
                  container
                  key={cat.title + entry.title}
                  className={classes.garmScoreCat}
                  style={{
                    marginTop: index === 0 ? 16 : 0,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={commonClasses.textXSBold}
                    style={{
                      color: '#686874',
                      position: 'absolute',
                      top: -8,
                      zIndex: 1,
                    }}
                  >
                    {getGarmTitle(entry.title)}
                    {entry.tooltip && (
                      <InfoTooltip
                        content={
                          <div>
                            {entry.tooltip}
                            {entry.tooltipLink ? (
                              <a
                                href={entry.tooltipLink.href}
                                className={classes.tooltipLink}
                              >
                                {entry.tooltipLink.label}
                              </a>
                            ) : null}
                          </div>
                        }
                      >
                        <InfoOutlined
                          fontSize='small'
                          style={{ marginLeft: 4, fontSize: 16 }}
                        />
                      </InfoTooltip>
                    )}
                  </Typography>
                  {cat.enabled && riskFilterEnabled(entry.jsonKeyRisk) && (
                    <Grid style={{ marginLeft: 16 }}>
                      <FilterSubSection
                        variant='range'
                        onChange={(_e, v) => {
                          if (!selectedCustomFilterTemp) {
                            handleFilterTempChange(entry.jsonKeyRisk, v);
                          }
                        }}
                        value={
                          selectedCustomFilterTemp
                            ? selectedCustomFilterTemp.data.scoreFilters[
                                entry.title
                              ]?.score
                            : getFilterTempValue(entry.jsonKeyRisk)
                        }
                        filterType={entry.filterType}
                      />
                      {!!selectedCustomFilterTemp?.data.scoreFilters[
                        entry.title
                      ]?.content?.length && (
                        <div
                          style={{
                            marginTop: -4,
                            marginLeft: -12,
                            marginBottom: 16,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                            maxWidth: 220,
                          }}
                        >
                          {selectedCustomFilterTemp?.data.scoreFilters[
                            entry.title
                          ].content.map(
                            (item: ScoreFilterType, filterIndex: number) =>
                              item.exceptions?.length ? (
                                <Typography
                                  key={filterIndex}
                                  style={{
                                    fontSize: 10,
                                    fontWeight: 600,
                                    color: '#F97316',
                                  }}
                                >
                                  {item.type === ScoreExceptionTypes.Genre
                                    ? 'Genre Exceptions: '
                                    : 'GARM Reasoning: '}
                                  {item.exceptions.map(
                                    (exception, exceptionIndex) => (
                                      <span key={exceptionIndex}>
                                        {exception.name} ({exception.risk})
                                        {exceptionIndex ===
                                        item.exceptions.length - 1
                                          ? ''
                                          : ', '}
                                      </span>
                                    )
                                  )}
                                </Typography>
                              ) : null
                          )}
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
              ))}
              <Grid
                container
                className={classes.garmScoreCat}
                style={{
                  width: '100%',
                }}
              >
                <Typography
                  className={commonClasses.textXSBold}
                  style={{
                    color: '#686874',
                    position: 'absolute',
                    top: -8,
                    zIndex: 1,
                  }}
                >
                  US Elections
                </Typography>

                <Grid style={{ marginLeft: 16, width: '100%' }}>
                  <SliderContainer
                    aria-label='US Elections'
                    value={
                      electionRangeTemp && electionRangeTemp.length === 2
                        ? electionRangeTemp
                        : [0, 100]
                    }
                    onChange={(_e, v) => setElectionRangeTemp(v as number[])}
                    valueLabelDisplay='auto'
                    marks={[
                      {
                        value: 0,
                        label: '0%',
                      },
                      {
                        value: 25,
                        label: '25%',
                      },
                      {
                        value: 50,
                        label: '50%',
                      },
                      {
                        value: 75,
                        label: '75%',
                      },
                      {
                        value: 100,
                        label: '100%',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        ))}
        <Grid
          container
          style={{
            marginTop: 40,
            marginBottom: 30,
          }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            onClick={() => {
              setOpenGenresCollapse(!openGenresCollapse);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Typography style={{ fontSize: 14, fontWeight: 'normal' }}>
              Genres
            </Typography>
            {openGenresCollapse ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Grid>
          <Collapse
            in={openGenresCollapse}
            timeout='auto'
            unmountOnExit
            style={{ width: '100%' }}
          >
            <Grid
              container
              className={classes.publishersContainer}
              onClick={() => setOpenGenres(!openGenres)}
            >
              {selectedGenresTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) => handleUnselectGenres(e, item)}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
              {excludedGenresTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) => handleUnexcludeGenres(e, item)}
                  style={{
                    background: 'rgb(232 29 80 / 88%)',
                    color: 'rgba(0,0,0,0.87)',
                  }}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
            </Grid>
          </Collapse>
        </Grid>
        <Grid
          container
          style={{
            marginTop: 40,
            marginBottom: 30,
          }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            onClick={() => {
              setOpenCustomTagsCollapse(!openCustomTagsCollapse);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Typography style={{ fontSize: 14, fontWeight: 'normal' }}>
              Custom Tags
            </Typography>
            {openCustomTagsCollapse ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Grid>
          <Collapse
            in={openCustomTagsCollapse}
            timeout='auto'
            unmountOnExit
            style={{ width: '100%' }}
          >
            <Grid
              container
              className={classes.publishersContainer}
              onClick={() => setOpenCustomTags(!openCustomTags)}
            >
              {selectedCustomTagsTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) => handleUnselectCustomTags(e, item)}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
              {excludedCustomTagsTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) =>
                    handleUnexcludeCustomTags(e, item)
                  }
                  style={{
                    background: 'rgb(232 29 80 / 88%)',
                    color: 'rgba(0,0,0,0.87)',
                  }}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
            </Grid>
          </Collapse>
        </Grid>
        <Grid
          container
          style={{
            marginTop: 40,
            marginBottom: 30,
          }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            onClick={() => {
              setOpenPublishers(!openPublishers);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Typography style={{ fontSize: 14, fontWeight: 'normal' }}>
              Publisher
            </Typography>
            {openPublishers ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Grid>
          <Collapse
            in={openPublishers}
            timeout='auto'
            unmountOnExit
            style={{ width: '100%' }}
          >
            <Grid
              container
              className={classes.publishersContainer}
              onClick={() => setOpenSelection(!openSelection)}
            >
              {selectedPublishersTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) => handleUnselectPublisher(e, item)}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
              {excludedPublishersTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) => handleUnexcludePublisher(e, item)}
                  style={{
                    background: 'rgb(232 29 80 / 88%)',
                    color: 'rgba(0,0,0,0.87)',
                  }}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
            </Grid>
          </Collapse>
        </Grid>
        <Grid
          container
          style={{
            marginTop: 40,
            marginBottom: 30,
          }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            onClick={() => {
              setOpenLanguagesCollapse(!openLanguagesCollapse);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Typography style={{ fontSize: 14, fontWeight: 'normal' }}>
              Languages
            </Typography>
            {openLanguagesCollapse ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Grid>
          <Collapse
            in={openLanguagesCollapse}
            timeout='auto'
            unmountOnExit
            style={{ width: '100%' }}
          >
            <Grid
              container
              className={classes.publishersContainer}
              onClick={() => setOpenLanguages(!openLanguages)}
            >
              {selectedLanguagesTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) => handleUnselectLanguages(e, item)}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {LANGUAGE_LIST.filter((language) => language.value === item)
                      ? LANGUAGE_LIST.filter(
                          (language) => language.value === item
                        )[0]?.label || item
                      : item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
              {excludedLanguagesTemp.map((item) => (
                <div
                  key={item}
                  onClick={(e: MouseEvent) => handleUnexcludeLanguages(e, item)}
                  style={{
                    background: 'rgb(232 29 80 / 88%)',
                    color: 'rgba(0,0,0,0.87)',
                  }}
                >
                  <Typography style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {LANGUAGE_LIST.filter((language) => language.value === item)
                      ? LANGUAGE_LIST.filter(
                          (language) => language.value === item
                        )[0]?.label || item
                      : item}
                  </Typography>
                  <CancelIcon fontSize='inherit' />
                </div>
              ))}
            </Grid>
          </Collapse>
        </Grid>
        <Grid
          container
          style={{
            marginTop: 40,
            marginBottom: 48,
          }}
        >
          <Typography
            style={{
              fontSize: 15,
              fontWeight: 'normal',
              padding: '0 8px',
            }}
          >
            Risk Level Recently Changed
          </Typography>
          <div className={classes.riskChangeFilter}>
            {['decrease', 'all', 'increase'].map((item) => (
              <div
                key={item}
                style={
                  item === riskChangeFilterTemp
                    ? { background: '#3E91DE80' }
                    : undefined
                }
                onClick={() => setRiskChangeFilterTemp(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </Grid>
      </Box>
      <NewDashboardButton
        variant='contained'
        onClick={() => enableApplyBtn && handleApplyFilter()}
        color='primary'
        className={classes.filterBtn}
        disabled={!enableApplyBtn}
      >
        Apply Filters
      </NewDashboardButton>
      <SelfScoringModal
        open={scoringPodcast}
        closeModal={() => setScoringPodcast(false)}
      />
    </FilterContainer>
  );
};

export default DashboardFilterSection;
