import { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Typography, TablePagination } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';
import Highlighter from 'react-highlight-words';
import { cloneDeep } from 'lodash';

import { getSearchResult } from 'src/apis/search';
import { getFilteredGarmShowNames } from 'src/apis';
import { getAllExceptionFilters } from 'src/apis/exception';
import {
  IGarmScoreDto,
  IGarmScoreShowDetails,
  GarmShowsRequestBody,
} from 'src/types';
import { SearchResultProps } from 'src/types/search';
import {
  BrandCustomFilter,
  ScoreFilterType,
  ScoreExceptionTypes,
} from 'src/types/filter';
import useSidebar from 'src/hooks/useSidebar';
import { useTextStyles } from 'src/hooks/useTextStyles';
import ButtonDropdown from 'src/components/ButtonDropdown';
import { formatFilename } from 'src/utils';
import Button from 'src/components/NewButton';
import {
  downloadAsCsv,
  formatPodcastRating,
  getGarmDashboards,
  convertRatings,
  getValidGarmScores,
} from 'src/utils';
import Spinner from 'src/components/Spinner';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import useAuth from 'src/hooks/useAuth';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import { RiskFilterType } from 'src/hooks/useDashboardFilters';

import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrowUp.svg';

import { MatchContent } from './MatchContent';
import SearchFilterSection from './SearchFilterSection';

const csvShowHeaders = [
  'id',
  'Show',
  'Host',
  'RSS Feed',
  'ImageURL',
  'collectionId',
  'Publisher',
  'Adult Sexual Explicit',
  'Arms & Ammunition',
  'Crime & Violation of Human Rights',
  'Death Injury or Military Conflict',
  'Online Piracy or Spam',
  'Hate Speech & Acts of Aggression',
  'bscenity & Profanity',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcoho',
  'Terrorism',
  'Debated Sensitive Social Issues (Political issues)',
  'General Election',
  'US Elections',
  'Genres',
  'Listener',
  'Top IAB Categories',
  'Episode Count',
];

const csvEpisodeHeaders = [
  'Episode Title',
  'Guid',
  'Adult Sexual Explicit Data',
  'Adult Sexual Explicit',
  'Arms & Ammunition Data',
  'Arms & Ammunition',
  'Crime & Violation of Human Rights Data',
  'Crime & Violation of Human Rights',
  'Death Injury or Military Conflict Data',
  'Death Injury or Military Conflict',
  'Online Piracy or Spam Data',
  'Online Piracy or Spam',
  'Hate Speech & Acts of Aggression Data',
  'Hate Speech & Acts of Aggression',
  'Obscenity & Profanity Data',
  'Obscenity & Profanity',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol Data',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol',
  'Terrorism Data',
  'Terrorism',
  'Debated Sensitive Social Issues (Political issues) Data',
  'Debated Sensitive Social Issues (Political issues) Highlight',
  'Debated Sensitive Social Issues (Political issues)',
  'GAMBLING Data',
  'GAMBLING',
  'OCCULT Data',
  'OCCULT',
  'NATURAL DISASTER Data',
  'NATURAL DISASTER',
  'General Election Data',
  'General Election',
  'US Election',
  'Audio File (AUTO)',
  'id',
  'Episode Link',
  'itunes stars',
  'Episode Date',
  'Show',
  'Episode Number',
  'Show Code',
  'IAB Data',
  'Matched Transcriptions',
];

const useStyles = makeStyles(() =>
  createStyles({
    layout: {
      display: 'flex',
      height: '100%',
    },
    container: {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      background: '#fff',
      height: '100%',
      overflow: 'auto',
      flex: 1,
    },
    title: {
      color: '#344054',
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',
    },
    options: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    noContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    contentItem: {
      padding: 16,
      borderRadius: 8,
      border: '1px solid #D0D5DD',
      background: '#FCFCFD',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    iabCategory: {
      padding: '2px 6px',
      borderRadius: 6,
      border: '1px solid #D0D5DD',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    pagination: {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 12,
      marginLeft: 'auto',
      border: '1px solid #D0D5DD',
      borderRadius: 12,
      padding: 0,

      '& .MuiTablePagination-toolbar': {
        minHeight: 'auto',
      },
    },
  })
);

interface OptionProps {
  label: string;
  value: string;
}

const SEARCH_GROUPS = [
  { label: 'By Episode', value: 'episode' },
  { label: 'By Show', value: 'show' },
];

const SEARCH_SORT = [
  { label: 'Most Relevant', value: 'relevant' },
  { label: 'Newest First', value: 'newest' },
  { label: 'Oldest First', value: 'oldest' },
];

const Search = () => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const [result, setResult] = useState<Nullable<SearchResultProps>>(null);
  const [groupedShows, setGroupedShows] = useState<IGarmScoreShowDetails[]>([]);
  const [openShowIndex, setOpenShowIndex] = useState<number>(-1);
  const [group, setGroup] = useState<OptionProps>(SEARCH_GROUPS[0]);
  const [sortMode, setSortMode] = useState<OptionProps>(SEARCH_SORT[0]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [excludedGenres, setExcludedGenres] = useState<string[]>([]);
  const [excludedCustomTags, setExcludedCustomTags] = useState<string[]>([]);
  const [excludedLanguages, setExcludedLanguages] = useState<string[]>([]);
  const [excludedPublishers, setExcludedPublishers] = useState<string[]>([]);
  const [selectedPublishers, setSelectedPublishers] = useState<string[]>([]);
  const [selectedDashboardId, setSelectedDashboardId] =
    useState<Nullable<string>>(null);
  const { setSecondSidebarOpen, setDashboardSidebar } = useSidebar();
  const [dashboardInit, setDashboardInit] = useState<boolean>(false);
  const [customFilters, setCustomFilters] = useState<BrandCustomFilter[]>([]);
  const [fetchedCustomFilters, setFetchedCustomFilters] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [selectedShows, setSelectedShows] = useState<string[] | undefined>(
    undefined
  );

  const { genres, publishers, dashboardId } = useDashboardFilters();
  const { organization } = useAuth();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('keyword');

  const { filterMapInit } = useDashboardCategories();

  const searchWords = useMemo(() => {
    if (!keyword) {
      return [];
    }
    if (keyword[0] === '"' && keyword[keyword.length - 1] === '"') {
      return [keyword.substring(1, keyword.length - 1)];
    } else {
      return keyword.split(' ');
    }
  }, [keyword]);

  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  const onChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchWithKeyword = useCallback(
    async (
      text: string,
      mode: string,
      page: number,
      pageSize: number,
      filterGenres: string[],
      filterPublishers: string[],
      selectedDashboardId: Nullable<string>
    ) => {
      if (loading) {
        return;
      }
      try {
        setLoading(true);
        let shows: string[] | undefined = undefined;
        if (selectedDashboardId) {
          const dashboard = garmDashboards.find(
            (item) => item.id === selectedDashboardId
          );
          if (dashboard) {
            const body: GarmShowsRequestBody = {};
            const garmSourceFilters = dashboard.keywordsList || [];
            if (garmSourceFilters.length) {
              body.sources = garmSourceFilters;
            }
            const genres =
              (dashboard.garmFilters?.genresList as string[]) || [];
            if (genres.length) {
              body.genres = genres;
            }
            const publishers =
              (dashboard.garmFilters?.publishersList as string[]) || [];
            if (publishers.length) {
              body.publishers = publishers;
            }
            const iabCategories =
              (dashboard.garmFilters?.iabCategories as any[]) || [];
            if (iabCategories.length) {
              body.iabCategories = [
                ...iabCategories.map((item) => item.name.toLowerCase()),
                ...iabCategories.map((item) => item.name),
              ];
            }
            const languages =
              (dashboard.garmFilters?.languages as string[]) || [];
            if (languages.length) {
              body.languages = languages;
            }
            const garmExceptions: any = {};
            const selectedCustomFilterId = dashboard.exceptionFilterId;
            const selectedCustomFilter = selectedCustomFilterId
              ? customFilters.filter(
                  (item) => item.id === selectedCustomFilterId
                )[0]
              : null;
            const filters = cloneDeep(filterMapInit);

            Object.values(dashboard.garmFilters || {}).forEach(
              (filter: any) => {
                const found = Object.entries(filterMapInit).find(
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  ([_, each]) =>
                    each.dashboardFilterKey === filter?.dashboardFilterKey
                );
                if (found) {
                  filters[found[0]] = {
                    ...filters[found[0]],
                    ...filter,
                    enabled: true,
                  };
                }
              }
            );
            const enabledFilters: RiskFilterType[] = [];

            Object.keys(filters).forEach((key) => {
              if (
                filters[key].enabled ||
                filters[key].filterType === 'audience'
              ) {
                enabledFilters.push(filters[key]);
              }
            });
            if (enabledFilters && enabledFilters.length) {
              enabledFilters.forEach((filter) => {
                const filterValue: number[] = filter.value as number[];
                if (filterValue && filter.jsonKeyRisk) {
                  if (filter.jsonKeyRisk === 'audience') {
                    const values = convertRatings(filterValue);
                    if (values) {
                      body.listenerCountRange = values;
                    }
                  } else {
                    const values = getValidGarmScores(filterValue);
                    if (values) {
                      garmExceptions[filter.dashboardFilterKey] = {
                        default: values,
                      };
                    }
                    if (selectedCustomFilter) {
                      const customFilter =
                        selectedCustomFilter.data.scoreFilters[filter.title];
                      if (customFilter) {
                        garmExceptions[filter.dashboardFilterKey] = {
                          default: getValidGarmScores(customFilter.score),
                        };
                        if (customFilter.content.length) {
                          const content: any = {};
                          customFilter.content.forEach(
                            (item: ScoreFilterType) => {
                              if (item.exceptions?.length) {
                                content[
                                  item.type === ScoreExceptionTypes.Genre
                                    ? 'genre'
                                    : 'garmReasoning'
                                ] = item.exceptions;
                              }
                            }
                          );
                          garmExceptions[filter.dashboardFilterKey] = {
                            ...garmExceptions[filter.dashboardFilterKey],
                            ...content,
                          };
                        }
                      }
                    }
                  }
                }
              });
            }

            body.garmExceptions = garmExceptions;
            const { showNames } = await getFilteredGarmShowNames(body);
            shows = showNames;
          }
        }

        setSelectedShows(shows);

        const { data } = await getSearchResult({
          page,
          pageSize,
          text,
          mode,
          genres: filterGenres,
          publishers: filterPublishers,
          shows,
        });
        setLoading(false);
        setResult(data);
        const seedIds = new Set();
        const newGroupedShows = data.shows.filter((show) => {
          if (!seedIds.has(show.id)) {
            seedIds.add(show.id);
            return true;
          }
          return false;
        });
        setGroupedShows(newGroupedShows);
        setFetchedData(true);
      } catch {
        setLoading(false);
        setFetchedData(true);
        setResult(null);
      }
    },
    [garmDashboards, customFilters, filterMapInit]
  );

  const fetchCustomFilters = useCallback(async () => {
    try {
      const data = await getAllExceptionFilters();
      setCustomFilters(data);
    } catch {
      setCustomFilters([]);
    } finally {
      setFetchedCustomFilters(true);
    }
  }, []);

  useEffect(() => {
    setOpenShowIndex(-1);
    setGroup(SEARCH_GROUPS[0]);
    setSortMode(SEARCH_SORT[0]);
    setSelectedGenres([]);
    setSelectedPublishers([]);
    setSelectedDashboardId(dashboardId);
    fetchCustomFilters();
  }, [keyword, fetchCustomFilters]);

  useEffect(() => {
    setSelectedDashboardId(dashboardId);
  }, [dashboardId]);

  useEffect(() => {
    if (selectedDashboardId) {
      setDashboardInit(true);
    }
  }, [selectedDashboardId]);

  useEffect(() => {
    if (fetchedCustomFilters && dashboardInit && keyword) {
      searchWithKeyword(
        keyword,
        sortMode.value,
        page,
        pageSize,
        selectedGenres,
        selectedPublishers,
        selectedDashboardId
      );
    }
  }, [
    keyword,
    sortMode,
    page,
    pageSize,
    selectedGenres,
    excludedGenres,
    selectedPublishers,
    selectedDashboardId,
    searchWithKeyword,
    dashboardInit,
    fetchedCustomFilters,
    excludedCustomTags,
    excludedLanguages,
    excludedPublishers,
  ]);

  useEffect(() => {
    setSecondSidebarOpen(false);
    setDashboardSidebar(false);
  }, []);

  const addDefaultSrc = (ev: any) => {
    ev.target.src = '/images/placeholder.png';
  };

  const episodeView = (
    episode: IGarmScoreDto,
    show: IGarmScoreShowDetails,
    isShowGroup?: boolean
  ) => (
    <Box
      className={classes.contentItem}
      style={isShowGroup ? { border: 'none' } : {}}
    >
      <Box display='flex' gridGap={16} alignItems='center'>
        {!isShowGroup && (
          <img
            src={show.logoUrl || formatFilename(show.showOnDB)}
            alt={`${name}`}
            onError={addDefaultSrc}
            style={{ width: 60, borderRadius: 10 }}
          />
        )}
        <Box display='flex' gridGap={4} flexDirection='column'>
          <Box
            display='flex'
            gridGap={16}
            alignItems='center'
            flexDirection='row'
          >
            <Typography
              className={textClasses.lgBold}
              style={{ color: '#344054' }}
            >
              <Highlighter
                searchWords={searchWords}
                autoEscape={true}
                highlightStyle={{
                  background: '#FDE272',
                }}
                style={{
                  whiteSpace: 'pre-line',
                }}
                textToHighlight={episode['Episode Title'] || ''}
              />
            </Typography>
            <Typography
              className={textClasses.xsRegular}
              style={{ color: '#5EA1F2' }}
            >
              <Highlighter
                searchWords={searchWords}
                autoEscape={true}
                highlightStyle={{
                  background: '#FDE272',
                }}
                style={{
                  whiteSpace: 'pre-line',
                }}
                textToHighlight={show.publisher || ''}
              />
            </Typography>
          </Box>
          <Box
            display='flex'
            gridGap={16}
            alignItems='center'
            flexDirection='row'
          >
            <Typography
              className={textClasses.xsRegular}
              style={{ color: '#98A2B3' }}
            >
              Aired{' '}
              {format(
                episode['Episode Date']
                  ? new Date(episode['Episode Date'])
                  : new Date(),
                'MM/dd/yyyy'
              )}
            </Typography>
            {show.genreList && (
              <Typography
                className={textClasses.xsRegular}
                style={{ color: '#98A2B3' }}
              >
                Genres:{' '}
                <Highlighter
                  searchWords={searchWords}
                  autoEscape={true}
                  highlightStyle={{
                    background: '#FDE272',
                  }}
                  style={{
                    whiteSpace: 'pre-line',
                  }}
                  textToHighlight={show.genreList}
                />
              </Typography>
            )}
            {(episode['IAB New Data'] || episode['IAB Data']) && (
              <Box display='flex' gridGap={8} alignItems='center'>
                <Typography
                  className={textClasses.xsRegular}
                  style={{ color: '#98A2B3' }}
                >
                  Top IAB Categories:
                </Typography>
                {episode['IAB New Data']
                  ? Object.keys(episode['IAB New Data'])
                      .slice(0, 3)
                      .map((item) => (
                        <Box key={item} className={classes.iabCategory}>
                          <Typography
                            className={textClasses.xxsRegular}
                            style={{ color: '#344054' }}
                          >
                            <Highlighter
                              searchWords={searchWords}
                              autoEscape={true}
                              highlightStyle={{
                                background: '#FDE272',
                              }}
                              style={{
                                whiteSpace: 'pre-line',
                              }}
                              textToHighlight={item}
                            />
                          </Typography>
                        </Box>
                      ))
                  : Object.keys(episode['IAB Data'][0][0])
                      .slice(0, 3)
                      .map((item) => (
                        <Box key={item} className={classes.iabCategory}>
                          <Typography
                            className={textClasses.xxsRegular}
                            style={{ color: '#344054' }}
                          >
                            <Highlighter
                              searchWords={searchWords}
                              autoEscape={true}
                              highlightStyle={{
                                background: '#FDE272',
                              }}
                              style={{
                                whiteSpace: 'pre-line',
                              }}
                              textToHighlight={item}
                            />
                          </Typography>
                        </Box>
                      ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {!isShowGroup && keyword && !!episode.Matches?.length && (
        <Box marginTop='16px'>
          <MatchContent
            searchWords={searchWords}
            matches={episode.Matches || []}
          />
        </Box>
      )}
    </Box>
  );

  const pageLoading = loading || !fetchedData || !fetchedCustomFilters;

  const noResult = !pageLoading && (!result || result.totalCount === 0);

  return (
    <Box className={classes.layout}>
      <SearchFilterSection
        dashboards={garmDashboards}
        selectedDashboardId={selectedDashboardId}
        setSelectedDashboardId={setSelectedDashboardId}
        genres={genres}
        selectedGenres={selectedGenres}
        excludedGenres={excludedGenres}
        setSelectedGenres={setSelectedGenres}
        setExcludedGenres={setExcludedGenres}
        publishers={publishers}
        selectedPublishers={selectedPublishers}
        setSelectedPublishers={setSelectedPublishers}
        excludedPublishers={excludedPublishers}
        setExcludedPublishers={setExcludedPublishers}
      />
      <Box className={classes.container}>
        <Box
          display='flex'
          gridGap={4}
          flexDirection='row'
          justifyContent='space-between'
        >
          <Typography className={classes.title}>
            {pageLoading || noResult ? '' : result && result.totalCount} Search
            results for ‘<span style={{ color: '#5EA1F2' }}>{keyword}</span>’
          </Typography>
          <Button
            height={36}
            onClick={async () => {
              if (
                downloading ||
                !keyword ||
                !result ||
                !result.episodes.length
              ) {
                return;
              }
              setDownloading(true);
              const { data } = await getSearchResult({
                page,
                pageSize: 500,
                text: keyword,
                mode: 'relevant',
                genres: selectedGenres,
                publishers: selectedPublishers,
                shows: selectedShows,
              });
              if (data?.episodes) {
                await downloadAsCsv({
                  data: data.episodes
                    .map((each) =>
                      [
                        each['Episode Title'],
                        each['Guid'],
                        JSON.stringify(each['GARM 1']),
                        each['GARM 1 Risk'],
                        JSON.stringify(each['GARM 2']),
                        each['GARM 2 Risk'],
                        JSON.stringify(each['GARM 3']),
                        each['GARM 3 Risk'],
                        JSON.stringify(each['GARM 4']),
                        each['GARM 4 Risk'],
                        JSON.stringify(each['GARM 5 & 9']),
                        each['GARM 5 & 9 Risk'],
                        JSON.stringify(each['GARM 6']),
                        each['GARM 6 Risk'],
                        JSON.stringify(each['GARM 7']),
                        each['GARM 7 Risk'],
                        JSON.stringify(each['GARM 8']),
                        each['GARM 8 Risk'],
                        JSON.stringify(each['GARM 10']),
                        each['GARM 10 Risk'],
                        JSON.stringify(each['GARM 11 DSSI Topics']),
                        JSON.stringify(each['GARM 11 DSSI Topics Utterances']),
                        each['GARM 11 Risk'],
                        JSON.stringify(each['GAMBLING']),
                        each['GAMBLING Risk'],
                        JSON.stringify(each['OCCULT']),
                        each['OCCULT Risk'],
                        JSON.stringify(each['NATURAL DISASTER']),
                        each['NATURAL DISASTER Risk'],
                        JSON.stringify(each['GENERAL ELECTIONS']),
                        each['GENERAL ELECTIONS Risk'],
                        (each['ELECTIONS PERCENTAGE'] || 0) + '%',
                        each['Audio File (AUTO)'],
                        each.id,
                        each['Episode Link'],
                        formatPodcastRating(each['Listeners'] || ''),
                        each['Episode Date'],
                        each.Show,
                        each['Episode Number'],
                        each['Show Code'],
                        each['IAB Data']
                          ? Object.keys(each['IAB Data'][0][0]).join(', ')
                          : '',
                        each.Matches?.length,
                      ]
                        .map((value) => {
                          if (!value) return value;
                          let result = String(value).replace(/"/g, '""');
                          if (result.search(/("|,|\n)/g) >= 0)
                            result = '"' + result + '"';
                          return result;
                        })
                        .join(',')
                    )
                    .join('\n'),
                  headers: csvEpisodeHeaders,
                  fileName: `${keyword}_Search_${page + 1}_page Episodes`,
                });
              }

              if (data?.shows) {
                await downloadAsCsv({
                  data: data.shows
                    .map((each: any) =>
                      [
                        each.id,
                        each.show,
                        each.hostList,
                        each.rssFeedUrl
                          ? each.rssFeedUrl.replaceAll('\r', '')
                          : '',
                        each.logoUrl,
                        each.collectionId,
                        each.publisher,
                        each.riskHistories?.current?.garm1Risk?.averageRisk,
                        each.riskHistories?.current?.garm2Risk?.averageRisk,
                        each.riskHistories?.current?.garm3Risk?.averageRisk,
                        each.riskHistories?.current?.garm4Risk?.averageRisk,
                        each.riskHistories?.current?.garm5Risk?.averageRisk,
                        each.riskHistories?.current?.garm6Risk?.averageRisk,
                        each.riskHistories?.current?.garm7Risk?.averageRisk,
                        each.riskHistories?.current?.garm8Risk?.averageRisk,
                        each.riskHistories?.current?.garm10Risk?.averageRisk,
                        each.riskHistories?.current?.garm11Risk?.averageRisk,
                        each.riskHistories?.current?.generalElectionsRisk
                          ?.averageRisk,
                        (each.electionsPercentage?.medianRiskValue || 0) + '%',
                        each.genreList,
                        each.listenerCount,
                        each.iabCategories
                          ? Object.keys(each.iabCategories).join(', ')
                          : '',
                        each.totalEpisodeCount,
                      ]
                        .map((value) => {
                          if (!value) return value;
                          let result = String(value).replace(/"/g, '""');
                          if (result.search(/("|,|\n)/g) >= 0)
                            result = '"' + result + '"';
                          return result;
                        })
                        .join(',')
                    )
                    .join('\n'),
                  headers: csvShowHeaders,
                  fileName: `${keyword}_Search_${page + 1}_page Shows`,
                });
              }
              setDownloading(false);
            }}
            style={{ marginLeft: 'auto' }}
            width={90}
          >
            {!downloading ? (
              <span>Export</span>
            ) : (
              <Spinner color='white' size={20} />
            )}
          </Button>
        </Box>
        {noResult ? (
          <Box className={classes.noContent}>
            <img
              src={'/images/search/noResultLogo.png'}
              width={200}
              height={160}
              alt='no result logo'
            />
            <Typography
              className={textClasses.xlBold}
              style={{ color: '#101828', marginTop: 24 }}
            >
              No results found
            </Typography>
            <Typography
              className={textClasses.baseRegular}
              style={{
                color: '#475467',
                marginTop: 8,
                textAlign: 'center',
                maxWidth: 352,
              }}
            >
              Your search “{keyword}“ did not find any results. Make sure your
              search term is properly spelled. Please try your search again with
              a different keyword.
            </Typography>
          </Box>
        ) : (
          <>
            <Box className={classes.options}>
              <ButtonDropdown
                id='search-group'
                selectedOption={group}
                options={SEARCH_GROUPS}
                handleSelect={setGroup}
              />
              <ButtonDropdown
                id='search-sort'
                selectedOption={sortMode}
                options={SEARCH_SORT}
                handleSelect={setSortMode}
              />
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                count={result?.totalCount || 0}
                page={page}
                rowsPerPage={pageSize}
                className={classes.pagination}
                nextIconButtonProps={{
                  size: 'small',
                }}
                backIconButtonProps={{
                  size: 'small',
                }}
              />
            </Box>
            {pageLoading ? (
              <Box className={classes.loading}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {group.value === 'episode' ? (
                  <Box className={classes.content}>
                    {result?.episodes.map((episode) => {
                      const show = groupedShows.filter(
                        (item) => item.show === episode.Show
                      )[0];
                      if (!show) {
                        return null;
                      }
                      return (
                        <Box key={episode.id}>{episodeView(episode, show)}</Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box className={classes.content}>
                    {groupedShows.map((show, showIndex) => (
                      <Box key={show.id}>
                        <Box
                          className={classes.contentItem}
                          style={
                            showIndex === openShowIndex
                              ? {
                                  borderRadius: '8px 8px 0 8px',
                                }
                              : {}
                          }
                        >
                          <Box display='flex' gridGap={16} alignItems='center'>
                            <img
                              src={
                                show.logoUrl || formatFilename(show.showOnDB)
                              }
                              alt={`${name}`}
                              onError={addDefaultSrc}
                              style={{ width: 60, borderRadius: 10 }}
                            />
                            <Box
                              display='flex'
                              flex={1}
                              gridGap={4}
                              flexDirection='column'
                            >
                              <Box
                                display='flex'
                                gridGap={16}
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Typography
                                  className={textClasses.lgBold}
                                  style={{ color: '#344054' }}
                                >
                                  <Highlighter
                                    searchWords={searchWords}
                                    autoEscape={true}
                                    highlightStyle={{
                                      background: '#FDE272',
                                    }}
                                    style={{
                                      whiteSpace: 'pre-line',
                                    }}
                                    textToHighlight={show.show}
                                  />
                                </Typography>
                                <Typography
                                  className={textClasses.xsRegular}
                                  style={{ color: '#5EA1F2' }}
                                >
                                  <Highlighter
                                    searchWords={searchWords}
                                    autoEscape={true}
                                    highlightStyle={{
                                      background: '#FDE272',
                                    }}
                                    style={{
                                      whiteSpace: 'pre-line',
                                    }}
                                    textToHighlight={show.publisher || ''}
                                  />
                                </Typography>
                                <Button
                                  color='secondary'
                                  disableElevation
                                  variant='text'
                                  size='small'
                                  height={34}
                                  onClick={() => {
                                    if (openShowIndex === showIndex) {
                                      setOpenShowIndex(-1);
                                    } else {
                                      setOpenShowIndex(showIndex);
                                    }
                                  }}
                                  style={{ marginLeft: 'auto' }}
                                  // className={classes.button}
                                >
                                  <ArrowUpIcon
                                    width={16}
                                    height={16}
                                    stroke='#344054'
                                    cursor='pointer'
                                    style={
                                      openShowIndex === showIndex
                                        ? {}
                                        : { transform: 'rotate(180deg)' }
                                    }
                                  />
                                  <Typography
                                    className={textClasses.xsBold}
                                    style={{
                                      color: '#344054',
                                    }}
                                  >
                                    Show Episodes
                                  </Typography>
                                </Button>
                              </Box>
                              <Box
                                display='flex'
                                gridGap={16}
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Typography
                                  className={textClasses.xsRegular}
                                  style={{ color: '#98A2B3' }}
                                >
                                  {show.listenerCount || 'No'} Listeners
                                </Typography>
                                <Typography
                                  className={textClasses.xsRegular}
                                  style={{ color: '#98A2B3' }}
                                >
                                  {show.totalEpisodeCount} Episodes
                                </Typography>
                                {show.genreList && (
                                  <Typography
                                    className={textClasses.xsRegular}
                                    style={{ color: '#98A2B3' }}
                                  >
                                    Genres:{' '}
                                    <Highlighter
                                      searchWords={searchWords}
                                      autoEscape={true}
                                      highlightStyle={{
                                        background: '#FDE272',
                                      }}
                                      style={{
                                        whiteSpace: 'pre-line',
                                      }}
                                      textToHighlight={show.genreList}
                                    />
                                  </Typography>
                                )}
                                {show.iabCategories && (
                                  <Box
                                    display='flex'
                                    gridGap={8}
                                    alignItems='center'
                                  >
                                    <Typography
                                      className={textClasses.xsRegular}
                                      style={{ color: '#98A2B3' }}
                                    >
                                      Top IAB Categories:
                                    </Typography>
                                    {Object.keys(show.iabCategories)
                                      .slice(0, 3)
                                      .map((item) => (
                                        <Box
                                          key={item}
                                          className={classes.iabCategory}
                                        >
                                          <Typography
                                            className={textClasses.xxsRegular}
                                            style={{ color: '#344054' }}
                                          >
                                            <Highlighter
                                              searchWords={searchWords}
                                              autoEscape={true}
                                              highlightStyle={{
                                                background: '#FDE272',
                                              }}
                                              style={{
                                                whiteSpace: 'pre-line',
                                              }}
                                              textToHighlight={item}
                                            />
                                          </Typography>
                                        </Box>
                                      ))}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {showIndex === openShowIndex && (
                          <Box
                            className={classes.contentItem}
                            style={{
                              marginLeft: 32,
                              padding: 0,
                              borderRadius: '0 0 8px 8px',
                            }}
                          >
                            {result?.episodes
                              .filter((episode) => episode.Show === show.show)
                              .map((episode) =>
                                episodeView(episode, show, true)
                              )}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Search;
