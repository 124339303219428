import clsx from 'clsx';
import { Fragment, useEffect, useMemo, useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
  AppBar as MuiAppBar,
  Avatar,
  Grid,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import BarometerLogo from 'src/components/BarometerLogo';
import { DashboardList } from 'src/components/sidebar/dashboard';
import DashboardFilterSection from 'src/components/sidebar/dashboard-filter-section';
import useAuth from 'src/hooks/useAuth';
import useSidebar, {
  DRAWER_WIDTH,
  DRAWER_WIDTH_SMALL,
} from 'src/hooks/useSidebar';
import useDashboardFilters, {
  RiskFilterType,
} from 'src/hooks/useDashboardFilters';
import LogOut from 'src/icons/LogOut';
import {
  getGarmDashboards,
  submitMetrics,
  METRICS_TYPE,
  ROUTES,
} from 'src/utils';
import { Dashboard, TSidebarLink } from 'src/types';
import { BrandCustomFilter } from 'src/types/filter';
import { useClickAway } from 'src/hooks/useClickAway';

import { IABFilter } from './IABFilter';
import { GenreFilter } from './GenreFilter';
import { CustomTagsFilter } from './CustomTagsFilter';
import { PublisherFilter } from './PublisherFilter';
import { BrandCustomFilters } from './BrandCustomFilters';
import { Links } from './constants';
import { useChangeFavoriteDashboard, useDeleteDashboard } from './hooks';
import { useStyles } from './AppBar.styles';
import { useTextStyles } from 'src/hooks/useTextStyles';
import { SearchModal } from 'src/modals/SearchModal';
import { LanguageFilter } from './LanguageFilter';

import { ReactComponent as SearchIcon } from 'src/assets/icons/searchFilled.svg';

export default function AppBar() {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const location = useLocation();
  const history = useHistory();
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openIABCategories, setOpenIABCategories] = useState<boolean>(false);
  const [openGenres, setOpenGenres] = useState<boolean>(false);
  const [openCustomTags, setOpenCustomTags] = useState<boolean>(false);
  const [openCustomFilters, setOpenCustomFilters] = useState<boolean>(false);
  const [isSettingsExpanded, setIsSettingsExpanded] = useState<boolean>(false);
  const [openLanguages, setOpenLanguages] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const {
    fullName,
    organization,
    user,
    logOut,
    updateOrganizationPrimaryDashboard,
  } = useAuth();
  const isViewer = user?.role === 'view';
  const publishersRef = useRef<any>();

  const {
    dashboardSidebarOpen,
    filterSidebarOpen,
    isSmallSidebar,
    secondSidebarOpen,
    setSecondSidebarOpen,
    setWidth,
    setDashboardSidebar,
    openFilterSidebar,
    toggleFilterSidebar,
  } = useSidebar();

  const {
    genres,
    customTags,
    publishers,
    selectDashboard,
    customFilters,
    refreshCustomFilters,
    dashboardName,
    dashboardId,
    garmSourceFilters,
    riskChangeFilter,
    selectedPublishers,
    selectedIABCategories,
    selectedGenres,
    excludedGenres,
    selectedLanguages,
    selectIABCategories,
    selectPuplisher,
    selectGenres,
    setExcludedGenres,
    setRiskChangeFilter,
    changeDashboardName,
    riskFilterEnabled,
    setGarmSourceFilters,
    selectedCustomFilter,
    setSelectedCustomFilter,
    setSelectedCustomFilterId,
    selectedSelfScoring,
    selectLanguages,
    electionRange,
    setElectionRange,
    selectedCustomTags,
    setSelectedCustomTags,
    enabledUpdateDashboard,
    setEnabledUpdateDashboard,
    riskFilters,
    setRiskFilters,
    excludedCustomTags,
    setExcludedCustomTags,
    excludedLanguages,
    setExcludedLanguages,
    excludedPublishers,
    setExcludedPublishers,
  } = useDashboardFilters();

  const [garmSourceFiltersTemp, setGarmSourceFiltersTemp] =
    useState<string[]>(garmSourceFilters);
  const [selectedIABCategoriesTemp, setSelectedIABCategoriesTemp] = useState<
    any[]
  >(selectedIABCategories);
  const [selectedPublishersTemp, setSelectedPublishersTemp] =
    useState<string[]>(selectedPublishers);
  const [selectedGenresTemp, setSelectedGenresTemp] =
    useState<string[]>(selectedGenres);
  const [excludedGenresTemp, setExcludedGenresTemp] =
    useState<string[]>(excludedGenres);
  const [selectedCustomTagsTemp, setSelectedCustomTagsTemp] =
    useState<string[]>(selectedCustomTags);
  const [selectedLanguagesTemp, setSelectedLanguagesTemp] =
    useState<string[]>(selectedLanguages);
  const [selectedCustomFilterTemp, setSelectedCustomFilterTemp] =
    useState<Nullable<BrandCustomFilter>>(selectedCustomFilter);
  const [riskFiltersTemp, setRiskFiltersTemp] = useState<
    Record<string, RiskFilterType>
  >(cloneDeep(riskFilters));
  const [electionRangeTemp, setElectionRangeTemp] =
    useState<number[]>(electionRange);
  const [riskChangeFilterTemp, setRiskChangeFilterTemp] =
    useState<string>(riskChangeFilter);
  const [selectedCustomFilterIdTemp, setSelectedCustomFilterIdTemp] =
    useState<string>(selectedCustomFilter?.id || '');
  const [excludedCustomTagsTemp, setExcludedCustomTagsTemp] =
    useState<string[]>(excludedGenres);
  const [excludedLanguagesTemp, setExcludedLanguagesTemp] =
    useState<string[]>(excludedGenres);
  const [excludedPublishersTemp, setExcludedPublishersTemp] =
    useState<string[]>(excludedGenres);
  const [enableApplyBtn, setEnableApplyBtn] = useState<boolean>(false);

  const { onChangeFavoriteDashboard } = useChangeFavoriteDashboard();

  const { onDeleteDashboard } = useDeleteDashboard();

  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  useEffect(() => {
    setGarmSourceFiltersTemp(garmSourceFilters);
    setSelectedIABCategoriesTemp(selectedIABCategories);
    setSelectedPublishersTemp(selectedPublishers);
    setSelectedGenresTemp(selectedGenres);
    setExcludedGenresTemp(excludedGenres);
    setSelectedCustomTagsTemp(selectedCustomTags);
    setSelectedLanguagesTemp(selectedLanguages);
    setSelectedCustomFilterTemp(selectedCustomFilter);
    setRiskFiltersTemp(cloneDeep(riskFilters));
    setElectionRangeTemp(electionRange);
    setRiskChangeFilterTemp(riskChangeFilter);
    setSelectedCustomFilterIdTemp(selectedCustomFilter?.id || '');
    setExcludedCustomTagsTemp(excludedCustomTags);
    setExcludedLanguagesTemp(excludedLanguages);
    setExcludedPublishersTemp(excludedPublishers);

    setEnableApplyBtn(false);
  }, [
    garmSourceFilters,
    selectedIABCategories,
    selectedPublishers,
    selectedGenres,
    excludedGenres,
    selectedCustomTags,
    selectedLanguages,
    selectedCustomFilter,
    riskFilters,
    electionRange,
    riskChangeFilter,
    dashboardId,
    excludedCustomTags,
    excludedLanguages,
    excludedPublishers,
  ]);

  // Select initial dashboard
  useEffect(() => {
    if (organization && !dashboardId) {
      const organizationDashboards = getGarmDashboards(organization);
      if (organizationDashboards.length) {
        const defaultDashboard =
          organizationDashboards.find(
            (item) => item.id === organization?.primaryDashboardId
          ) || organizationDashboards[0];
        selectDashboard(defaultDashboard);
        openFilterSidebar();
      }
    }
  }, [organization, dashboardId]);

  useEffect(() => {
    if (location.pathname.includes('/dashboard') && !isViewer) {
      setSecondSidebarOpen(true);
    }
  }, [location, isViewer]);

  useEffect(() => {
    if (openCustomFilters) {
      refreshCustomFilters();
    }
  }, [openCustomFilters]);

  const handleLogout = () => {
    submitMetrics(METRICS_TYPE.USER_LOGOUT);
    logOut();
  };

  const handleClickLink = (link: TSidebarLink) => {
    if (link.text === 'Dashboards') {
      setDashboardSidebar(!dashboardSidebarOpen);
      refreshCustomFilters();
    } else {
      setSecondSidebarOpen(false);
      setDashboardSidebar(false);
      selectDashboard(null);
    }
  };

  const handleSelectDashboard = (dashboard: Dashboard) => {
    selectDashboard(dashboard);
    setDashboardSidebar(false);
    if (location.pathname !== ROUTES.DASHBOARD) {
      history.push(ROUTES.DASHBOARD);
    }
    if (!isViewer) {
      setSecondSidebarOpen(true);
    }

    if (!filterSidebarOpen) {
      toggleFilterSidebar();
    }
  };

  const handleToggleSidebar = () => {
    setWidth(!isSmallSidebar ? DRAWER_WIDTH_SMALL : DRAWER_WIDTH);
  };

  const handleClickAway = () => {
    setOpenSelection(false);
  };

  useClickAway({ ref: publishersRef, handleClickAway });

  const handleCloseIABCategories = () => {
    setOpenIABCategories(false);
  };

  const handleMakePrimary = (id: string) => {
    updateOrganizationPrimaryDashboard(id);
  };

  const handleDelete = async (dashboardId: string) => {
    await onDeleteDashboard(dashboardId);
    if (garmDashboards && garmDashboards.length) {
      if (dashboardId === garmDashboards[0].id) {
        garmDashboards[1] && selectDashboard(garmDashboards[1]);
      } else {
        selectDashboard(garmDashboards[0]);
      }
    }
  };
  const handleSettingsExpand = () => {
    setIsSettingsExpanded(!isSettingsExpanded);
  };

  const setFilterValue = (key: string, value: number | number[]) => {
    const f = { ...riskFiltersTemp };
    if (f[key]) {
      f[key].value = value;
      setRiskFiltersTemp(f);
    }
  };

  const handleFilterTempChange = (key: string, n: number | number[]) => {
    setFilterValue(key, n);
  };

  const getFilterTempValue = (key: string) => riskFiltersTemp[key]?.value;

  const handleApplyFilter = async () => {
    if (!enabledUpdateDashboard) {
      await new Promise<void>((resolve) => {
        setGarmSourceFilters(garmSourceFiltersTemp);
        selectIABCategories(selectedIABCategoriesTemp);
        selectPuplisher(selectedPublishersTemp);
        selectGenres(selectedGenresTemp);
        setExcludedGenres(excludedGenresTemp);
        setSelectedCustomTags(selectedCustomTagsTemp);
        selectLanguages(selectedLanguagesTemp);
        setSelectedCustomFilter(selectedCustomFilterTemp);
        setRiskFilters(cloneDeep(riskFiltersTemp));
        setElectionRange(electionRangeTemp);
        setRiskChangeFilter(riskChangeFilterTemp);
        setSelectedCustomFilterId(selectedCustomFilterIdTemp);
        setExcludedCustomTags(excludedCustomTagsTemp);
        setExcludedLanguages(excludedLanguagesTemp);
        setExcludedPublishers(excludedPublishersTemp);
        resolve();
      });

      setEnabledUpdateDashboard(true);
      setEnableApplyBtn(false);
    }
  };

  if (
    !organization ||
    location.pathname === ROUTES.LANDING
    // location.pathname === ROUTES.REGISTER
  ) {
    return null;
  }

  return (
    <MuiAppBar
      color='inherit'
      position='fixed'
      className={clsx({ SmallSidebar: isSmallSidebar })}
    >
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Drawer
          className={classes.drawer}
          variant='permanent'
          classes={{ paper: classes.drawerPaper }}
          anchor='left'
        >
          <Grid
            item
            container
            alignItems='center'
            wrap='nowrap'
            justifyContent='flex-start'
            className={classes.logoContainer}
          >
            <Box className={classes.menuContainer}>
              <MenuOpenIcon
                htmlColor='#757575'
                className={classes.menuOpenIcon}
                onClick={handleToggleSidebar}
              />

              {!isSmallSidebar && <BarometerLogo height={37} />}
            </Box>
          </Grid>
          <List className={classes.listContainer} component='nav'>
            {Links.map((link) =>
              link.text === 'Team' && isViewer ? (
                <></>
              ) : (
                <Fragment key={link.text}>
                  {link.text === 'Settings' ? (
                    <ListItem
                      button
                      className={classes.listItem}
                      selected={location.pathname === link.href}
                      classes={{ selected: classes.active }}
                      onClick={() => {
                        handleSettingsExpand();
                      }}
                    >
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      {!isSmallSidebar && <ListItemText primary={link.text} />}
                      {!isSmallSidebar && (
                        <ListItemIcon className={classes.itemRightSideIcon}>
                          {isSettingsExpanded ? (
                            <KeyboardArrowUpIcon
                              cursor='pointer'
                              onClick={handleSettingsExpand}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              cursor='pointer'
                              onClick={handleSettingsExpand}
                            />
                          )}
                        </ListItemIcon>
                      )}
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      to={link.href}
                      component={Link}
                      className={classes.listItem}
                      selected={location.pathname === link.href}
                      classes={{ selected: classes.active }}
                      onClick={() => {
                        handleClickLink(link);
                      }}
                    >
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      {!isSmallSidebar && <ListItemText primary={link.text} />}
                      {!isSmallSidebar && link.text === 'Settings' && (
                        <ListItemIcon className={classes.itemRightSideIcon}>
                          {isSettingsExpanded ? (
                            <KeyboardArrowUpIcon
                              cursor='pointer'
                              onClick={handleSettingsExpand}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              cursor='pointer'
                              onClick={handleSettingsExpand}
                            />
                          )}
                        </ListItemIcon>
                      )}
                    </ListItem>
                  )}
                  <Collapse
                    in={link.text === 'Settings' && isSettingsExpanded}
                    timeout='auto'
                    unmountOnExit
                    style={{ paddingLeft: 16 }}
                  >
                    {link.children &&
                      link.children.map((item) => (
                        <ListItem
                          key={item.text}
                          button
                          to={item.href}
                          component={Link}
                          className={classes.listItem}
                          selected={location.pathname === item.href}
                          classes={{ selected: classes.active }}
                          onClick={() => {
                            handleClickLink(item);
                          }}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          {!isSmallSidebar && (
                            <ListItemText primary={item.text} />
                          )}
                        </ListItem>
                      ))}
                  </Collapse>
                </Fragment>
              )
            )}

            <Box height={80} style={{ marginTop: 'auto' }} />
            <Box className={classes.subList}>
              <Box onClick={() => setOpenSearch(true)}>
                <SearchIcon />
                <Typography className={textClasses.xsMedium}>Search</Typography>
                <Box className='searchShortKey'>
                  <Typography className={textClasses.xsMedium}>⌘S</Typography>
                </Box>
              </Box>
            </Box>

            <ListItem
              button
              className={clsx(
                classes.listItem,
                classes.logOutButton,
                isSmallSidebar ? classes.logOutSmall : null
              )}
              onClick={handleLogout}
            >
              <Avatar>{fullName?.[0] ?? ''}</Avatar>
              {!isSmallSidebar && (
                <>
                  <ListItemText primary={fullName} />
                  <ListItemIcon>
                    <LogOut color='rgba(0, 0, 0, 0.54)' />
                  </ListItemIcon>
                </>
              )}
            </ListItem>
          </List>
        </Drawer>
        {secondSidebarOpen && (
          <Drawer
            anchor='left'
            classes={{ paper: classes.drawerPaperSecondary }}
            className={classes.drawerExt}
            variant='permanent'
          >
            <DashboardFilterSection
              openSelection={openSelection}
              openIABCategories={openIABCategories}
              openGenres={openGenres}
              onDelete={handleDelete}
              onMakePrimary={handleMakePrimary}
              setOpenSelection={setOpenSelection}
              setOpenIABCategories={setOpenIABCategories}
              setOpenGenres={setOpenGenres}
              openCustomFilters={openCustomFilters}
              setOpenCustomFilters={setOpenCustomFilters}
              openLanguages={openLanguages}
              setOpenLanguages={setOpenLanguages}
              openCustomTags={openCustomTags}
              setOpenCustomTags={setOpenCustomTags}
              dashboardId={dashboardId}
              dashboardName={dashboardName}
              garmSourceFiltersTemp={garmSourceFiltersTemp}
              setGarmSourceFiltersTemp={(value) => {
                setEnableApplyBtn(true);
                setGarmSourceFiltersTemp(value);
              }}
              audienceTemp={getFilterTempValue('audience')}
              setAudienceTemp={(value) => {
                setEnableApplyBtn(true);
                handleFilterTempChange('audience', value);
              }}
              changeDashboardName={changeDashboardName}
              selectedIABCategoriesTemp={selectedIABCategoriesTemp}
              setSelectedIABCategoriesTemp={(value) => {
                setEnableApplyBtn(true);
                setSelectedIABCategoriesTemp(value);
              }}
              selectedPublishersTemp={selectedPublishersTemp}
              setSelectedPublishersTemp={(value) => {
                setEnableApplyBtn(true);
                setSelectedPublishersTemp(value);
              }}
              selectedGenresTemp={selectedGenresTemp}
              setSelectedGenresTemp={(value) => {
                setEnableApplyBtn(true);
                setSelectedGenresTemp(value);
              }}
              excludedGenresTemp={excludedGenresTemp}
              setExcludedGenresTemp={(value) => {
                setEnableApplyBtn(true);
                setExcludedGenresTemp(value);
              }}
              selectedCustomTagsTemp={selectedCustomTagsTemp}
              setSelectedCustomTagsTemp={(value) => {
                setEnableApplyBtn(true);
                setSelectedCustomTagsTemp(value);
              }}
              selectedLanguagesTemp={selectedLanguagesTemp}
              setSelectedLanguagesTemp={(value) => {
                setEnableApplyBtn(true);
                setSelectedLanguagesTemp(value);
              }}
              selectedSelfScoring={selectedSelfScoring}
              selectedCustomFilterTemp={selectedCustomFilterTemp}
              setSelectedCustomFilterTemp={(value) => {
                setEnableApplyBtn(true);
                setSelectedCustomFilterTemp(value);
              }}
              setSelectedCustomFilterId={(value) => {
                setEnableApplyBtn(true);
                setSelectedCustomFilterIdTemp(value);
              }}
              riskFilterEnabled={riskFilterEnabled}
              handleFilterTempChange={(key, value) => {
                setEnableApplyBtn(true);
                handleFilterTempChange(key, value);
              }}
              getFilterTempValue={getFilterTempValue}
              electionRangeTemp={electionRangeTemp}
              setElectionRangeTemp={(value) => {
                setEnableApplyBtn(true);
                setElectionRangeTemp(value);
              }}
              riskChangeFilterTemp={riskChangeFilterTemp}
              setRiskChangeFilterTemp={(value) => {
                setEnableApplyBtn(true);
                setRiskChangeFilterTemp(value);
              }}
              handleApplyFilter={handleApplyFilter}
              enableApplyBtn={enableApplyBtn}
              excludedCustomTagsTemp={excludedCustomTagsTemp}
              setExcludedCustomTagsTemp={(value) => {
                setEnableApplyBtn(true);
                setExcludedCustomTagsTemp(value);
              }}
              excludedLanguagesTemp={excludedLanguagesTemp}
              setExcludedLanguagesTemp={(value) => {
                setEnableApplyBtn(true);
                setExcludedLanguagesTemp(value);
              }}
              excludedPublishersTemp={excludedPublishersTemp}
              setExcludedPublishersTemp={(value) => {
                setEnableApplyBtn(true);
                setExcludedPublishersTemp(value);
              }}
            />
          </Drawer>
        )}
        {dashboardSidebarOpen && (
          <Drawer
            anchor='left'
            classes={{ paper: classes.drawerPaperSecondary }}
            className={classes.drawerExt}
            variant='permanent'
          >
            <DashboardList
              selectedDashboardId={dashboardId}
              dashboards={garmDashboards}
              onChangeFavorite={onChangeFavoriteDashboard}
              onSelectDashboard={handleSelectDashboard}
            />
          </Drawer>
        )}
        <IABFilter
          isOpen={openIABCategories}
          isSmallSidebar={isSmallSidebar}
          handleClose={handleCloseIABCategories}
          selectedIABCategories={selectedIABCategoriesTemp}
          setSelectedIABCategories={(value) => {
            setEnableApplyBtn(true);
            setSelectedIABCategoriesTemp(value);
          }}
        />
        <GenreFilter
          genres={genres}
          selectedGenres={selectedGenresTemp}
          excludedGenres={excludedGenresTemp}
          isOpen={openGenres}
          isSmallSidebar={isSmallSidebar}
          handleClose={() => {
            setOpenGenres(false);
          }}
          handleSave={(data) => {
            setEnableApplyBtn(true);
            setSelectedGenresTemp(data);
            setOpenGenres(false);
          }}
          handleExclude={(data) => {
            setEnableApplyBtn(true);
            setExcludedGenresTemp(data);
            setOpenGenres(false);
          }}
        />
        <CustomTagsFilter
          customTags={customTags}
          selectedCustomTags={selectedCustomTagsTemp}
          excludedCustomTags={excludedCustomTagsTemp}
          isOpen={openCustomTags}
          isSmallSidebar={isSmallSidebar}
          handleClose={() => {
            setOpenCustomTags(false);
          }}
          handleSave={(data) => {
            setEnableApplyBtn(true);
            setSelectedCustomTagsTemp(data);
            setOpenCustomTags(false);
          }}
          handleExclude={(data) => {
            setEnableApplyBtn(true);
            setExcludedCustomTagsTemp(data);
            setOpenCustomTags(false);
          }}
        />
        <PublisherFilter
          publishers={publishers}
          selectedPublishers={selectedPublishersTemp}
          excludedPublishers={excludedPublishersTemp}
          isOpen={openSelection}
          isSmallSidebar={isSmallSidebar}
          handleClose={() => {
            setOpenSelection(false);
          }}
          handleSave={(data) => {
            setEnableApplyBtn(true);
            setSelectedPublishersTemp(data);
            setOpenSelection(false);
          }}
          handleExclude={(data) => {
            setEnableApplyBtn(true);
            setExcludedPublishersTemp(data);
            setOpenSelection(false);
          }}
        />
        <LanguageFilter
          isOpen={openLanguages}
          isSmallSidebar={isSmallSidebar}
          handleClose={() => setOpenLanguages(false)}
          selectedLanguages={selectedLanguagesTemp}
          excludedLanguages={excludedLanguagesTemp}
          setSelectedLanguages={(value) => {
            setEnableApplyBtn(true);
            setSelectedLanguagesTemp(value);
          }}
          setExcludedLanguages={(data) => {
            setEnableApplyBtn(true);
            setExcludedLanguagesTemp(data);
          }}
        />
        <BrandCustomFilters
          filters={customFilters}
          isOpen={openCustomFilters}
          isSmallSidebar={isSmallSidebar}
          handleClose={() => {
            setOpenCustomFilters(false);
          }}
          isSelectedId={selectedCustomFilter?.id}
          handleSelect={(item) => {
            setEnableApplyBtn(true);
            setSelectedCustomFilterIdTemp(item.id);
            setSelectedCustomFilterTemp(
              customFilters.filter((filter) => filter.id === item.id)[0]
            );
            setOpenCustomFilters(false);
          }}
        />
        <SearchModal
          open={openSearch}
          closeModal={() => setOpenSearch(false)}
        />
      </Box>
    </MuiAppBar>
  );
}
