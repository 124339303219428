import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactNode, useEffect, useRef, useState } from 'react';
import useToggle from 'src/hooks/toggle/useToggle';
import useEnvironment from 'src/hooks/useEnvironment';
import {
  ListIcon,
  OverTimeIcon,
  OverviewIcon,
  TagsIcon,
} from 'src/icons/dashboard';
import TimeRangeSelector from '../../TimeRangeSelector';
import { ROUTES } from 'src/utils';

const coreButtonStyles = {
  fontWeight: 'normal',
  letterSpacing: 0.15,
  textTransform: 'none',
  padding: '6px 8px 6px 12px',
};

export const ButtonGroupContainer = styled(ButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 999,
  boxShadow: '0px 0px 0px 1px #E0E0E0',
  '& .TimeRangeSelector': {
    ...coreButtonStyles,
    color: '#666666',
    fontSize: 16,
  },
}));
export const ButtonWrapper = styled(Button)(({ theme }) => ({
  '&': {
    ...coreButtonStyles,
  },
  '& .MuiSvgIcon-root': {
    marginLeft: theme.spacing(1.375),
  },
  '&:first-child': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '999px 0 0 999px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(62, 145, 222, 0.8)',
    },
  },
  '&:last-child': {
    borderRadius: '0 999px 999px 0',
  },
  '& .menu-selected': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    '& svg': {
      fill: '#fff',
      fillOpacity: 1,
    },
  },
}));
export const Container = styled(Grid)(() => ({
  '& .DashboardSelection': {
    left: '114px !important',
    top: '4px !important',
    zIndex: 100,
    '& .MuiListItem-root': {
      whiteSpace: 'normal',
    },
  },
}));

interface EnvOptionType {
  id: string;
  icon?: ReactNode;
  secondaryIcon?: ReactNode;
  description: string;
  title: string;
  show: boolean;
}

const DASHBOARD_MENU_OPTIONS: EnvOptionType[] = [
  {
    id: 'Overview',
    description:
      'Get a sense of Editorial Bias, Sensationalism and other metrics regarding your data.',
    icon: <OverviewIcon />,
    secondaryIcon: <OverviewIcon fill='#fff' />,
    title: 'Overview',
    show: false,
  },
  {
    id: 'Tags',
    description:
      'See a rank of the most used terms in your thesaurus and know what people say about it.',
    icon: <TagsIcon />,
    secondaryIcon: <TagsIcon fill='#fff' />,
    title: 'Tags',
    show: false,
  },
  {
    id: 'Over time',
    description:
      'Have an overview of metrics over time and in other aggregate form.',
    icon: <OverTimeIcon />,
    secondaryIcon: <OverTimeIcon fill='#fff' />,
    title: 'Over time & Aggregated',
    show: false,
  },
  {
    id: 'List',
    description:
      'Manage your records and analyze them one by one lorem ipsum dolor sit amet.',
    icon: <ListIcon />,
    secondaryIcon: <ListIcon fill='#fff' fillOpacity='1.0' />,
    title: 'List',
    show: false,
  },
  {
    id: 'MRT',
    description:
      'Manage your records and analyze them one by one lorem ipsum dolor sit amet.',
    icon: <ListIcon />,
    secondaryIcon: <ListIcon fill='#fff' fillOpacity='1.0' />,
    title: 'MRT - Media Roundtable Values',
    show: false,
  },
  {
    id: 'GARM',
    description: '',
    icon: <ListIcon />,
    secondaryIcon: <ListIcon fill='#fff' fillOpacity='1.0' />,
    title: 'Contextual Suitability',
    show: true,
  },
  {
    id: 'Host',
    description: '',
    icon: <ListIcon />,
    secondaryIcon: <ListIcon fill='#fff' fillOpacity='1.0' />,
    title: 'Host Intelligence',
    show: true,
  },
  {
    id: 'Non-English',
    description: '',
    icon: <ListIcon />,
    secondaryIcon: <ListIcon fill='#fff' fillOpacity='1.0' />,
    title: 'Non-English Content',
    show: true,
  },
];

const MenuSelection = () => {
  const history = useHistory();
  const { dashboardEnv, setDashboardEnv, timeRange, setTimeRange } =
    useEnvironment();
  const [selectedOption, setSelectedOption] = useState<
    EnvOptionType | undefined
  >(undefined);
  const [open, toggleMenu] = useToggle();
  const anchorRef = useRef<any>(null);

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    toggleMenu(false);
  };

  const handleSelectMenu = (event: any, id: string) => {
    setDashboardEnv(id);
    handleClose(event);
    history.push(ROUTES.DASHBOARD + '/' + id);
  };

  // Return focus after menu closes
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setSelectedOption(
      DASHBOARD_MENU_OPTIONS.find((option) => option.id === dashboardEnv)
    );
  }, [dashboardEnv]);

  return (
    <Container>
      {selectedOption && (
        <ButtonGroupContainer variant='text'>
          <ButtonWrapper
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={() => toggleMenu()}
          >
            <div className='menu-selected'>
              {selectedOption.secondaryIcon}
              <span>
                {selectedOption.id === 'GARM'
                  ? 'Contextual Suitability'
                  : selectedOption.id}
              </span>
            </div>
            <ArrowDropDownIcon fill='rgba(0, 0, 0, 0.54)' />
          </ButtonWrapper>
          <TimeRangeSelector defaultRange={timeRange} onChange={setTimeRange} />
        </ButtonGroupContainer>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className='DashboardSelection'
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  style={{ width: 364 }}
                >
                  {DASHBOARD_MENU_OPTIONS.map(
                    (option) =>
                      option.show && (
                        <MenuItem
                          onClick={(e) => handleSelectMenu(e, option.id)}
                          alignItems='flex-start'
                          key={option.title}
                        >
                          {option?.icon && (
                            <ListItemIcon>{option.icon}</ListItemIcon>
                          )}
                          <ListItemText
                            primary={option.title}
                            secondary={option.description}
                          />
                        </MenuItem>
                      )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

export default MenuSelection;
