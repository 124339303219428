import { useEffect, useState } from 'react';

import useEnvironment from './useEnvironment';
import { RiskFilterType } from './useDashboardFilters';
import { IGarmCategory } from 'src/types';

export const GARMCategories: IGarmCategory[] = [
  {
    title: 'Brand Suitability Filters',
    enabled: true,
    tooltip:
      'The Global Alliance for Responsible Media (GARM) is an industry first effort that unites marketers, media agencies, media platforms, and industry associations to safeguard the potential of digital media by reducing the availability and monetization of harmful content online. These 11 components are a part of the Brand Suitability Framework, designed to support identification of sensitive content appropriate for advertising supported by enhanced advertiser controls.',
    entries: [
      {
        title: 'Adult Sexual Explicit',
        jsonKeyRisk: 'GARM 1 Risk',
        jsonKeyData: 'GARM 1',
        dashboardFilterKey: 'garm1Risk',
        tooltip:
          'This component speaks to the presence and treatment of adult or sexually explicit language.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Obscenity & Profanity',
        jsonKeyRisk: 'GARM 7 Risk',
        jsonKeyData: 'GARM 7',
        dashboardFilterKey: 'garm7Risk',
        tooltip:
          'This component speaks to the presence and treatment of obscene and profane language.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Debated Sensitive Social Issues (Political issues)',
        jsonKeyRisk: 'GARM 11 Risk',
        jsonKeyData: 'GARM 11 DSSI Topics',
        jsonKeyDataHighlight: 'GARM 11 DSSI Topics Utterances',
        dashboardFilterKey: 'garm11Risk',
        tooltip:
          'This component speaks to the presence and treatment of debated sensitive social issues.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol',
        jsonKeyRisk: 'GARM 8 Risk',
        jsonKeyData: 'GARM 8',
        dashboardFilterKey: 'garm8Risk',
        tooltip:
          'This component speaks to the presence and treatment of illegal drugs, tobacco and alcohol.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Arms & Ammunition',
        jsonKeyRisk: 'GARM 2 Risk',
        jsonKeyData: 'GARM 2',
        dashboardFilterKey: 'garm2Risk',
        tooltip:
          'This component speaks to the presence and treatment of discussions pertaining to arms and ammunition.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Online Piracy or Spam',
        jsonKeyRisk: 'GARM 5 & 9 Risk',
        jsonKeyData: 'GARM 5 & 9',
        dashboardFilterKey: 'garm5Risk',
        tooltip:
          'This component measures the presence and treatment of discussions pertaining to both GARM 5: Online Piracy and GARM 9: Spam or Harmful content.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Terrorism',
        jsonKeyRisk: 'GARM 10 Risk',
        jsonKeyData: 'GARM 10',
        dashboardFilterKey: 'garm10Risk',
        tooltip:
          'This component speaks to the presence and treatment of Terrorism in podcast content.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Death, Injury or Military Conflict',
        jsonKeyRisk: 'GARM 4 Risk',
        jsonKeyData: 'GARM 4',
        dashboardFilterKey: 'garm4Risk',
        tooltip:
          'This component speaks to the presence and treatment of Death, Injury and Military Conflict in podcast data.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Crime & Violation of Human Rights',
        jsonKeyRisk: 'GARM 3 Risk',
        jsonKeyData: 'GARM 3',
        dashboardFilterKey: 'garm3Risk',
        tooltip:
          'This component speaks to the presence and treatment of Crime, Harmful Acts, and Human Rights Violations in podcast data.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Hate Speech & Acts of Aggression',
        jsonKeyRisk: 'GARM 6 Risk',
        jsonKeyData: 'GARM 6',
        dashboardFilterKey: 'garm6Risk',
        tooltip:
          'This component speaks to the presence and treatment of hate speech.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Gambling',
        jsonKeyRisk: 'GAMBLING Risk',
        jsonKeyData: 'GAMBLING',
        dashboardFilterKey: 'gamblingRisk',
        tooltip: '',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Occult',
        jsonKeyRisk: 'OCCULT Risk',
        jsonKeyData: 'OCCULT',
        dashboardFilterKey: 'occultRisk',
        tooltip: '',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'Natural Disasters',
        jsonKeyRisk: 'NATURAL DISASTER Risk',
        jsonKeyData: 'NATURAL DISASTER',
        dashboardFilterKey: 'naturalDisasterRisk',
        tooltip: '',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
      {
        title: 'General Election',
        jsonKeyRisk: 'GENERAL ELECTIONS Risk',
        jsonKeyData: 'GENERAL ELECTIONS',
        dashboardFilterKey: 'generalElectionsRisk',
        tooltip: '',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
    ],
  },
];

export const MRTCategories: IGarmCategory[] = [
  {
    title: 'MRT Values',
    enabled: true,
    tooltip:
      'The Media Roundtable (MRT) consists of media, brands, and marketers coming together to change the way we express our differences in news media and opinion journalism.The media roundtable consists of media, brands, and marketers coming together to change the way we express our differences in news media and opinion journalism. They support advertisers, marketers, and media in their efforts to give preference in exposure and ad dollars toward content that exhibits the following values',
    entries: [
      {
        title: 'Tolerance',
        jsonKeyRisk: 'Tolerance',
        jsonKeyData: null,
        filterType: 'tolerance',
        dashboardFilterKey: 'toleranceRisk',
        tooltip:
          'This MRT component speaks to the tolerance present in discussions of debated sensitive social issues.',
        tooltipLink: {
          label: 'Learn more',
          href: '/documentation',
        },
      },
    ],
  },
  {
    title: 'Coming Soon',
    enabled: false,
    entries: [
      {
        title: 'Clarity',
        jsonKeyRisk: '',
      },
      {
        title: 'Respect',
        jsonKeyRisk: '',
      },
      {
        title: 'Fairness',
        jsonKeyRisk: '',
      },
      {
        title: 'De-escalation',
        jsonKeyRisk: '',
      },
    ],
  },
];

const useDashboardCategories = () => {
  const { dashboardEnv } = useEnvironment();
  const [dashboardCategories, setDashboardCategories] =
    useState<IGarmCategory[]>(GARMCategories);
  const [filterMapInit, setFilterMapInit] = useState<
    Record<string, RiskFilterType>
  >({});
  const [riskKeyByTitleMap, setRiskKeyByTitleMap] = useState<
    Record<string, string>
  >({});
  const [riskComponents, setRiskComponents] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    let categories;

    switch (dashboardEnv) {
      case 'GARM':
        categories = GARMCategories;
        break;
      case 'MRT':
        categories = MRTCategories;
        break;
      default:
        categories = GARMCategories;
    }

    setDashboardCategories(categories);
    const newFilterMapInit: Record<string, RiskFilterType> = {};
    const newRiskKeyByTitleMap: Record<string, string> = {};
    const newRiskComponents: Record<string, string> = {};
    newFilterMapInit.audience = {
      enabled: false,
      value: [0, 5],
      title: '',
      jsonKeyRisk: 'audience',
      filterType: 'audience',
      dashboardFilterKey: 'audience',
    };

    categories.forEach((cat) => {
      cat.entries.forEach((entry: any) => {
        if (!!entry.jsonKeyRisk) {
          newRiskKeyByTitleMap[entry.title] = entry.jsonKeyRisk;
          newFilterMapInit[entry.jsonKeyRisk] = {
            enabled: cat.enabled,
            value: [0, 3],
            title: entry.title,
            jsonKeyRisk: entry.jsonKeyRisk,
            filterType: entry.filterType,
            dashboardFilterKey: entry.dashboardFilterKey,
          };
          newRiskComponents[entry.title] = entry.dashboardFilterKey;
        }
      });
    });

    setFilterMapInit(newFilterMapInit);
    setRiskKeyByTitleMap(newRiskKeyByTitleMap);
    setRiskComponents(newRiskComponents);
  }, [dashboardEnv]);

  return {
    dashboardCategories,
    filterMapInit,
    riskKeyByTitleMap,
    riskComponents,
  };
};

export default useDashboardCategories;
